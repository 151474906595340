<div class="admin-container">
  <div class="admin-table-container">
    <h5 class="admin-title">Email settings</h5>
    <p class="admin-subhead">
      If media uploads are delayed, users can still receive notifications without media. They will receive an email saying "No preview
      available" along with all telemetry data.
    </p>
    <div class="email-notification-table" *ngIf="!isLoading; else loader">
      <table
        mat-table
        *ngIf="dataSource && dataSource.data && dataSource.data.length > 0"
        matSort
        class="table"
        [dataSource]="dataSource"
        matSortActive="timestamp"
        matSortDirection="desc">
        <ng-container matColumnDef="organization">
          <th mat-header-cell class="table-header" *matHeaderCellDef>Organization</th>
          <td mat-cell class="organization" *matCellDef="let element; let i = index" id="{{ 'vi_unit_head' + (i + 1) }}">
            <span class="text-field">{{ element.name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="sendEmail">
          <th mat-header-cell class="table-header" *matHeaderCellDef>Send email</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_admin_send_notification' + (i + 1) }}">
            <mat-button-toggle-group
              disableRipple="true"
              id="vi_camera_profile_alerts_view_mode"
              [value]="element.emailWithMedia"
              (change)="selectionChange($event.value, element)"
              class="switch-btn">
              <mat-button-toggle value="with"> With media </mat-button-toggle>
              <mat-button-toggle value="without"> Without media </mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdated">
          <th mat-header-cell class="table-header" *matHeaderCellDef>Last updated at</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_admin_last_updated' + (i + 1) }}">
            <span class="text-field">{{ element.createdAt | date : 'medium' : currentZone }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdatedBy">
          <th mat-header-cell class="table-header" *matHeaderCellDef>Last updated by</th>
          <td mat-cell class="custom" *matCellDef="let element; let i = index" id="{{ 'vi_admin' + 'last_updated_by' + (i + 1) }}">
            <span class="text-field">{{ element.createdBy }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="table-content" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="btn-details">
    <button type="submit" class="btn reset-btn" [disabled]="!isDirty" [color]="primary" (click)="resetSettings()" slb-secondary-button>
      Reset
    </button>
    <button type="submit" class="btn save-btn" [disabled]="disableSave" (click)="saveSettings()" slb-button>Save</button>
  </div>
</div>
<ng-template #loader>
  <div class="spinner">
    <app-spinner></app-spinner>
  </div>
</ng-template>
<slb-toast></slb-toast>
