import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationInput } from './confirmation-input';
import { ConfirmationResult } from './confirmation-result';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public ConfirmationResult = ConfirmationResult;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: ConfirmationInput) {}
}
