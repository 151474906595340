<div class="event-review-title">
  <div class="event-review-header">
    <span mat-dialog-title class="review-title">Review</span>
    <div class="modal-close-icon">
      <slb-modal-close-button id="event_review_close_btn" buttonType="Icon" [dialogRef]="dialogRef"></slb-modal-close-button>
    </div>
  </div>
  <div class="view-container" *ngIf="!isLoading; else loader">
    <div class="view">
      <mat-icon svgIcon="preview" class="view-icon"></mat-icon>
      <span class="view-label">{{ viewCount }} Views</span>
    </div>
    <div class="flag-button">
      <slb-radio-button-group
        (valueChanged)="setFlagData()"
        [isVertical]="false"
        [(ngModel)]="currentFlag"
        [values]="flagTypes"></slb-radio-button-group>
    </div>
  </div>
  <div class="bottom-container" *ngIf="!isLoading">
    <div class="remark-container">
      <div class="remark-label">
        <div class="user-container">
          <div class="user-icon">{{ getInitials(userName) }}</div>
          <span class="user-label">{{ userName }}</span>
        </div>
      </div>
      <div class="comments">
        <mat-form-field slbFormField class="text-area-field">
          <textarea
            matInput
            class="comment"
            cdkFocusInitial
            type="textarea"
            [(ngModel)]="comment"
            (input)="checkComment()"
            id="agora_vi_event_review_comment_textarea"
            maxlength="2000"
            rows="4"
            placeholder="Write your thoughts"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button slb-secondary-button id="agora_vi_event_review_save_btn" [disabled]="isPostDisabled" (click)="postComment()" class="save">
        Save
      </button>
    </div>
  </div>
  <ng-template #loader>
    <div class="spinner">
      <mat-spinner *ngIf="isLoading" [diameter]="40"></mat-spinner>
    </div>
  </ng-template>
</div>
