<div id="notification-setting-container" *ngIf="!isLoading; else loader">
  <div id="notification_setting" class="notification">
    <div class="notification-heading">
      <h4>Notification settings</h4>
      <p>Select notification settings as per your requirement</p>
    </div>
    <div class="show-notification">
      <p class="show-time">
        Show notification
        <mat-button-toggle-group
          disableRipple="true"
          (change)="switchView($event)"
          id="vi_notification_view_mode"
          [value]="viewMode"
          class="button-group">
          <mat-button-toggle *ngFor="let data of notificationType" value="{{ data.id }}"> {{ data.name }} </mat-button-toggle>
        </mat-button-toggle-group>
      </p>
    </div>
  </div>
  <div id="notification_system_notification" class="system-notification">
    <div class="accordion-header">
      <div>
        <h4>System notification</h4>
        <p class="system-details">
          Alert when any of the hardware, software or integrated components are not functional or partially functional as detected by the
          intelligent operations hardware and operating systems.
        </p>
      </div>
      <div class="accordion-function">
        <mat-slide-toggle class="toggle" [(ngModel)]="isSystem" (click)="system()"></mat-slide-toggle>
        <mat-icon svgIcon="arrow-down-3" class="icon" (click)="isSystemOpen = !isSystemOpen" *ngIf="isSystemOpen"></mat-icon>
        <mat-icon svgIcon="arrow-up-3" class="icon" (click)="isSystemOpen = !isSystemOpen" *ngIf="!isSystemOpen"></mat-icon>
      </div>
    </div>
    <div class="system-content-wrap" [ngClass]="{ 'close-accordion': !isSystemOpen }">
      <div class="system-content">
        <mat-checkbox
          class="checkbox"
          id="vi_notification_checkbox"
          (change)="setSystemData()"
          [(ngModel)]="isCameraFunction"
          (ngModelChange)="enableBtn()"
          [disabled]="!isSystem"></mat-checkbox>
        <p class="system-details">Camera is not functioning well</p>
      </div>
      <div class="system-content">
        <mat-checkbox
          class="checkbox"
          (change)="setSystemData()"
          [(ngModel)]="isAutomationControl"
          (ngModelChange)="enableBtn()"
          id="vi_notification_checkbox"
          [disabled]="!isSystem"></mat-checkbox>
        <p class="system-details">Automation/ Control system (WITS)</p>
      </div>
    </div>
  </div>
  <div id="notification_trend_notification" class="system-notification">
    <div class="accordion-header">
      <div>
        <h4>Trend notification</h4>
        <p class="system-details">
          Alert when any of the activated workflows (checked) have recorded an increasing trend of activities for the identified time
          periods (UTC time zones are used).
        </p>
      </div>
      <div class="accordion-function">
        <mat-slide-toggle class="toggle" [(ngModel)]="isTrend" (ngModelChange)="enableBtn()"></mat-slide-toggle>
        <mat-icon svgIcon="arrow-down-3" class="icon" (click)="isTrendOpen = !isTrendOpen" *ngIf="isTrendOpen"></mat-icon>
        <mat-icon svgIcon="arrow-up-3" class="icon" (click)="isTrendOpen = !isTrendOpen" *ngIf="!isTrendOpen"></mat-icon>
      </div>
    </div>
    <div class="system-content-wrap" [ngClass]="{ 'close-accordion': !isTrendOpen }">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="camera">
          <th mat-header-cell *matHeaderCellDef>Camera</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_trend_table' + '_camera_' + (i + 1) }}">
            {{ element.equipmentName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="workflow">
          <th mat-header-cell *matHeaderCellDef>Workflow</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_trend_table' + '_workflow_' + (i + 1) }}">
            {{ element.workflowName | uppercase }}
          </td>
        </ng-container>
        <ng-container matColumnDef="min">
          <th mat-header-cell *matHeaderCellDef>
            Decreasing % <mat-icon svgIcon="info" slbTooltip="{{ minInfo }}" class="info-icon"></mat-icon>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_trend_table' + '_min_' + (i + 1) }}">
            <mat-select
              [(ngModel)]="element.min"
              class="min-max-field"
              [disabled]="!isTrend"
              (ngModelChange)="enableBtn()"
              hideSingleSelectionIndicator="true">
              <mat-option *ngFor="let min of minMaxData" [value]="min">
                {{ min }}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>
        <ng-container matColumnDef="max">
          <th mat-header-cell *matHeaderCellDef>
            Increasing % <mat-icon svgIcon="info" [slbTooltip]="maxInfo" class="info-icon"></mat-icon>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_trend_table' + '_max_' + (i + 1) }}">
            <mat-select
              [(ngModel)]="element.max"
              class="min-max-field"
              [disabled]="!isTrend"
              (ngModelChange)="enableBtn()"
              hideSingleSelectionIndicator="true">
              <mat-option *ngFor="let max of minMaxData" [value]="max">
                {{ max }}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>
        <ng-container matColumnDef="isNotified">
          <th mat-header-cell *matHeaderCellDef>No notification</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_trend_table' + '_event_time_' + (i + 1) }}">
            <p class="no-notification">
              <mat-checkbox
                class="checkbox"
                id="vi_notification_table_checkbox"
                [(ngModel)]="element.noNotification"
                (ngModelChange)="enableBtn()"
                [disabled]="!isTrend"></mat-checkbox>
              No notification since 24 hours
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
    </div>
  </div>
  <div class="btn-details">
    <span class="mx-10">Last updated: {{ lastUpdated | date : 'mediumDate' }}</span>
    <button type="button" class="save-btn reset-btn" slb-button [disabled]="disableResetBtn" (click)="resetData(); disableApplyBtn = false">
      Reset
    </button>
    <button type="submit" class="save-btn" slb-button (click)="save()" [disabled]="isError || disableApplyBtn">Save</button>
  </div>
</div>
<ng-template #loader>
  <div class="spinner">
    <app-spinner></app-spinner>
  </div>
</ng-template>
<slb-toast></slb-toast>
