export const ADMIN_DISPLAYED_COLUMNS = ['organization', 'sendEmail', 'lastUpdated', 'lastUpdatedBy'];
export const EMAIL_NOTIFICATION = {
  SET_EMAIL_NOTIFICATION_ORG_ERROR: 'Error while receiving organization details',
  SET_EMAIL_NOTIFICATION_HIST_ERROR: 'Error while receiving email settings',
  SET_EMAIL_NOTIFICATION_SAVE_ERROR: 'Error while saving email settings',
  SET_EMAIL_NOTIFICATION_SUCCESS: 'Email settings saved successfully',
  ALL: 'All',
  WITH: 'with',
  WITHOUT: 'without',
  PRIMARY: 'primary',
};
