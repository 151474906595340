import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationCamera } from '../../shared/models/notification';
import { DatePipe } from '@angular/common';
import { NOTIFICATION } from '../../shared/constants/notification-constant';

@Component({
  selector: 'app-system-notification-table',
  templateUrl: './system-notification-table.component.html',
  styleUrls: ['./system-notification-table.component.scss'],
})
export class SystemNotificationTableComponent implements OnChanges {
  @Input() data: NotificationCamera | null | undefined;
  @Input() currentZone: string;
  public displayedColumns = ['cameraName', 'date', 'offlineTime', 'currentStatus'];
  public dataSource = new MatTableDataSource<NotificationCamera>();

  constructor(private datePipe: DatePipe) {}

  ngOnChanges(): void {
    if (this.data) {
      this.dataSource.data.push(this.data);
    }
  }

  public getTime(data: string): string | null {
    const getDate = data.split('_');
    let endDate = null;
    const startDate = new DatePipe('en-Us').transform(getDate[0], NOTIFICATION.DATE_FORMAT_24, this.currentZone);
    if (getDate.length > 1) {
      endDate = new DatePipe('en-Us').transform(getDate[1], NOTIFICATION.DATE_FORMAT_24, this.currentZone);
    }
    if (startDate && endDate) {
      return startDate + ' to ' + endDate;
    }

    return startDate;
  }
}
