import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constant';
import { environment } from '../../../environments/environment';
import { GlobalData } from '../models/eventDetails';
import { GlobalChartSummary, WorkFlow } from '../models/global-view';
import { riskStatus } from '../models/dashabordEventDetails';

@Injectable({
  providedIn: 'root',
})
export class GlobalViewService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  public getWorkFlowId(): Observable<WorkFlow[]> {
    return this.http.get<WorkFlow[]>(`${this.baseUrl}${ApiConstants.BASEURL}WorkFlow`);
  }

  public getSiteList(countries: string[]): Observable<WorkFlow[]> {
    const param = {
      countries,
    };

    return this.http.post<WorkFlow[]>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}asset-list`, param);
  }

  public getRiskList(): Observable<riskStatus[]> {
    return this.http.get<riskStatus[]>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}get-risk-categories`);
  }

  public getEventChart(
    startDate: string,
    endDate: string,
    timeZone: string,
    filterData: { [key: string]: string[] },
    showAllEvents: string
  ): Observable<GlobalChartSummary> {
    const param = {
      startDate,
      endDate,
      timeZone,
      ...filterData,
      showAllEvents,
    };

    return this.http.post<GlobalChartSummary>(`${this.baseUrl}${ApiConstants.BASEURL}Event/get-event-chart`, param);
  }

  public getEventDetails(
    startDate: string,
    endDate: string,
    filterData: { [key: string]: string[] },
    showAllEvents: string,
    pageNo: number,
    records: number,
    timeZone?: string
  ): Observable<GlobalData> {
    const param = {
      startDate,
      endDate,
      ...filterData,
      showAllEvents,
      pageNo,
      records,
      timeZone,
    };

    return this.http.post<GlobalData>(`${this.baseUrl}${ApiConstants.BASEURL}Event/get-events-details`, param);
  }
}
