<div class="dashboard-timezone">
  <mat-form-field appearance="outline" floatLabel="always" id="vi_timezone_dropdown_dashboard">
    <mat-select
      [(ngModel)]="currentZoneDetails"
      (selectionChange)="onTimezoneChange()"
      class="timezone-field"
      hideSingleSelectionIndicator="true">
      <mat-option *ngFor="let zone of timezoneList" [value]="zone">
        {{ zone.label + ' ' + zone.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="all-chart-container" *ngIf="!summaryLoader && !riskLoader; else chartLoader">
  <div class="graph-chart-container">
    <mat-card class="no-link" id="vi_total_gateway_chart_dashboard">
      <mat-card-header>
        <mat-card-title id="vi_total_gateway_chart_dashboard_header"
          >Total events: {{ totalEvents }}
          <mat-card-subtitle class="sub-label">{{ chartTimeLine }}</mat-card-subtitle>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="chart-container">
        <div class="event-chart" *ngIf="totalEvents; else no_data">
          <highcharts-chart [Highcharts]="Highcharts" [options]="eventLineChartOption" [(update)]="updateEventLineChart"></highcharts-chart>
        </div>
      </mat-card-content>
      <ng-template #no_data>
        <p class="align-center" id="vi_dashboard_gateways_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
  </div>
  <div class="doughnut-charts-container">
    <mat-card class="no-link" id="vi_total_violation_chart_dashboard">
      <mat-card-header class="event-header">
        <mat-card-title id="vi_total_violation_chart_dashboard_header"> Event status</mat-card-title>
        <mat-card-subtitle class="sub-label">{{ chartTimeLine }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="chart-container">
        <div *ngIf="totalVoilation; else no_event_status">
          <highcharts-chart [Highcharts]="Highcharts" [options]="eventChartOptions" [(update)]="updateChart"></highcharts-chart>
        </div>
      </mat-card-content>
      <ng-template #no_event_status>
        <p class="align-center" id="vi_dashboard_cameras_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
    <mat-card class="no-link" id="vi_camer_profile_risk_chart_container">
      <mat-card-header class="event-header">
        <mat-card-title id="vi_total_violation_chart_dashboard_header"> At risk events </mat-card-title>
        <mat-card-subtitle class="sub-label">{{ chartTimeLine }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="chart-container">
        <div>
          <highcharts-chart
            *ngIf="eventOptions.series && eventOptions.series.length > 0; else risk_no_data"
            [Highcharts]="Highcharts"
            [options]="eventOptions"
            [(update)]="updateRiskChart">
          </highcharts-chart>
        </div>
      </mat-card-content>
      <ng-template #risk_no_data>
        <p class="align-center" id="vi_camer_profile_violation_chart_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
  </div>
</div>
<ng-template #chartLoader>
  <mat-card>
    <mat-card-content class="doughnut-charts-loader"> <app-spinner></app-spinner> </mat-card-content>
  </mat-card>
</ng-template>
