<div class="vi_camera-profile_alerts-container" id="vi_camera_profile_alerts_container">
  <div class="alert-head">
    <div class="alert-heading">
      <mat-checkbox
        class="checkbox"
        id="vi_camera_profile_checkbox"
        [(ngModel)]="checkAll"
        *ngIf="viewMode === 'grid'"
        [disabled]="violationDataList && !violationDataList.length"
        (change)="checkAllFiles()"
        [indeterminate]="
          violationDataList && selectedFiles && selectedFiles.length !== 0 && selectedFiles.length !== violationDataList.length
        "></mat-checkbox>
      <h4>Events</h4>
      <button
        slb-button
        class="download-btn"
        id="vi_camera_profile_alerts_download"
        (click)="downloadSelectedFile()"
        [disabled]="(selectedFiles && !selectedFiles.length) || isDisableButton">
        Download
      </button>
      <button
        slb-button
        class="download-btn"
        id="vi_camera_profile_alerts_review"
        (click)="openReviewPopup()"
        [disabled]="(selectedFiles && !selectedFiles.length) || selectedFiles.length > 1 || isDisableButton">
        Review
      </button>
      <mat-button-toggle-group
        disableRipple="true"
        (change)="switchView($event)"
        id="vi_camera_profile_alerts_view_mode"
        [value]="viewMode"
        class="download-btn">
        <mat-button-toggle value="grid"> Grid view </mat-button-toggle>
        <mat-button-toggle value="table"> Table view </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <p class="no-data" *ngIf="violationDataList && !violationDataList.length && !isLoading">No data available</p>
  <div *ngIf="viewMode === 'grid'; else showTable">
    <app-global-event-grid
      [isLoading]="isLoading"
      [gridData]="violationDataList"
      id="vi_camera_profile_alerts_grid"
      [paginatedData]="paginatedData"
      (selectedData)="getSelectedFiles($event)"
      (pageData)="getPaginatedData($event)"
      [eventId]="eventId"
      (cameraPopOverClosed)="setEventId()"
      [totalNoRecords]="totalRecords"></app-global-event-grid>
  </div>
  <ng-template #showTable>
    <app-global-table
      id="vi_camera_profile_alerts_table"
      [isLoading]="isLoading"
      [gridData]="violationDataList"
      (selectedData)="getSelectedFiles($event)"
      (pageData)="getPaginatedData($event)"
      [paginatedData]="paginatedData"
      [totalNoRecords]="totalRecords"></app-global-table>
  </ng-template>
  <slb-toast></slb-toast>
</div>
