export class ZoneConfigurationConstants {
  public static ZONEGETMEDIAERROR = 'Error while receiveing media details';
  public static ZONEGETIMAGEERROR = 'Unable to get image, Please try later';
  public static ZONEGETVIDEOERROR = 'Error while receiveing video details';
  public static ZONEGETERROR = 'Error while receiveing zone details';
  public static ZONESAVEERROR = 'Error while saving zone details';
  public static DETAILERROR = 'No Record Found';
  public static ZONEREQUESTERROR = 'No latest image available';
  public static GATEWAYDISABLED = 'Gateway is disconnected.';
  public static CAMERADISABLED = 'Camera is disconnected.';
  public static ZONEFAILEDERROR = 'Please try again.';
  public static CONFIRMMESSAGE = 'Your changes will be discarded, do you want to continue?';
  public static ZONE_POINT_LIMIT_EXCEED_MESSAGE = 'You have reached the defined limit of number of points.';
  public static ZONE_DEFINATION_INTERSECTION_MESSAGE = 'Lines are intersecting, you can not draw further.';
  public static ZONEREQUESTSUCCESS = 'You have configured the zone successfully.';
  public static ZONESETTINGSUCCESS = 'You have updated the zone setting successfully.';
  public static GATEWAYCAMERADISABLEERROR = 'Camera or gateway is disabled so not able to define a zone';
  public static NOZONEDEFINATION = 'Click on “Request image” button for zone definition';
  public static DEVICEOFFLINEWITHZONECONFIG = 'The operation can not be performed at this moment because camera or gateway is disconnected';

  public static PPEEVENTS = [
    {
      img: 'assets/icons/icon-helmet.svg',
      isChecked: false,
      label: 'Helmet',
    },
    {
      img: 'assets/icons/icon-coverall.svg',
      isChecked: false,
      label: 'Cover all',
    },
    {
      img: 'assets/icons/icon-gloves.svg',
      isChecked: false,
      label: 'Gloves',
    },
    {
      img: 'assets/icons/icon-highvisibility.svg',
      isChecked: false,
      label: 'High-visibility vest',
    },
    {
      img: 'assets/icons/icon-goggle.svg',
      isChecked: false,
      label: 'Goggle',
    },
    {
      img: 'assets/icons/icon-safetyboots.svg',
      isChecked: false,
      label: 'Safety Boots',
    },
  ];
}
