<table
  mat-table
  *ngIf="dataSource && dataSource.data && dataSource.data.length > 0"
  [dataSource]="dataSource"
  class="mat-elevation-z8 system-table">
  <ng-container matColumnDef="cameraName">
    <th mat-header-cell *matHeaderCellDef>Camera Name</th>
    <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_system_table' + '_event_time_' + (i + 1) }}">
      {{ element.cameraName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_system_table' + '_event_time_' + (i + 1) }}">
      {{ element.date | date : 'MM/dd/yyyy' : currentZone }}
    </td>
  </ng-container>
  <ng-container matColumnDef="offlineTime">
    <th mat-header-cell *matHeaderCellDef>Offline Time</th>
    <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_system_table' + '_event_time_' + (i + 1) }}">
      <p *ngFor="let time of element.offlineTime">
        {{ getTime(time) }}
      </p>
    </td>
  </ng-container>
  <ng-container matColumnDef="currentStatus">
    <th mat-header-cell *matHeaderCellDef>Current Status</th>
    <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_notification_system_table' + '_event_time_' + (i + 1) }}">
      {{ element.cameraCurrentStatus ? 'Offline' : 'Online' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
