import { WorkFlow } from '../models/global-view';

export const NOTIFICATION = {
  MIN_MAX_SELECT: ['NA', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
  DAILY: 'Daily',
  TREND_TABLE_COLUMN: ['camera', 'workflow', 'min', 'max', 'isNotified'],
  SYSTEM_DATA_ERROR: 'Error while receiving system data',
  NOTIFICATION_TYPE_ERROR: 'Error while receiving notification type',
  GET_NOTIFICATION__ERROR: 'Error while receiving notification details',
  GET_WORKFLOW_ERROR: 'Error while receiving workflow details',
  SET_NOTIFICATION_ERROR: 'Error while saving notification details',
  SET_NOTIFICATION_SUCCESS: 'Notification details saved successfully',
  GETNOTIFICATIONDATAERROR: 'Error while getting notification details',
  ALL: 'All',
  NOTIFICATION_URL: '/notification',
  SYSTEM: 'system',
  TREND: 'trend',
  ALL_EVENT: 'all',
  NOT_APPLICABLE: 'NA',
  CAMERA_NOT_FUNCTION: 'Camera is not functioning well',
  DATE_FORMAT_24: 'h:mm:ss a',
  MAX_INFO:
    'When the trend at the chosen period (daily or weekly) </br>increases above this threshold then an alert notification is triggered </br>if the user choses N/A option then no notification will</br> be sent when an upward trend is detected',
  MIN_INFO:
    'When the trend at the chosen frequency (daily or weekly)</br> falls below this threshold then an alert notification is triggered </br>if the user choses N/A option then no notification will</br> be sent when a downward trend is detected',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const system_data: WorkFlow[] = [
  {
    id: 'c2be18b8-fb48-4618-8601-561faeddef51',
    name: 'Automation/Control system(WITS)',
  },
  {
    id: '22447895-3993-4a45-8e12-5b404655b5f6',
    name: 'Camera is not functioning well',
  },
];
