import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Gateway, RigDetails } from '../models/camera';
import { ApiConstants } from '../constants/api-constant';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  public getGatewayHierarchy(country: string): Observable<Gateway> {
    return this.http.get<Gateway>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.GATEWAY}get-gateway-hierarchy?country_name=${country}`
    );
  }

  public getCameraList(
    gatewayId: string | null,
    controllerId: string | null,
    pageNumber: number,
    recordPerPage: number,
    country: string
  ): Observable<RigDetails> {
    return this.http.get<RigDetails>(
      /* eslint-disable max-len */
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.CAMERA}${gatewayId}/${controllerId}/${pageNumber}/${recordPerPage}?countryName=${country}`
    );
  }
}
