<div class="app-content" id="vi_global_view">
  <div class="global-view-container">
    <div class="filter">
      <div class="filter-container">
        <div class="header-container">
          <p class="header">Filter by</p>
        </div>
        <div class="filter-option">
          <mat-expansion-panel [expanded]="true" class="date-time-selector-container">
            <mat-expansion-panel-header class="date-time-selector">
              <p class="date-time-header">Date & Time</p>
            </mat-expansion-panel-header>
            <slb-radio-button-group
              class="date-range"
              [isVertical]="false"
              [values]="customOption"
              [(ngModel)]="selectedOption"
              (valueChanged)="onCustom()"></slb-radio-button-group>
            <div class="calendar-container" [formGroup]="formGroup">
              <div class="start-date-holder">
                <mat-form-field slbFormField class="calendar">
                  <mat-label>From</mat-label>
                  <input
                    formControlName="startDate"
                    [(ngModel)]="custStartDate"
                    (formattedValueChange)="formattedCalendar = $event"
                    id="custom_calendar_dialog_startDate"
                    (valueChange)="calendarEvent = $event"
                    [slbDateTimePicker]="dateTimePicker"
                    (ngModelChange)="validateCalendar()"
                    [max]="todayDate"
                    [placeholder]="calendarPlaceholder"
                    matInput />
                  <slb-date-time-picker-toggle [for]="dateTimePicker" matSuffix [disabled]="!getEnableCalendar">
                  </slb-date-time-picker-toggle>
                  <slb-date-time-picker-popup #dateTimePicker></slb-date-time-picker-popup>
                </mat-form-field>
              </div>
              <div class="end-date-holder">
                <mat-form-field slbFormField class="calendar">
                  <mat-label>To</mat-label>
                  <input
                    formControlName="endDate"
                    [(ngModel)]="custEndDate"
                    (formattedValueChange)="formattedCalendar = $event"
                    id="custom_calendar_dialog_endDate"
                    (valueChange)="calendarEvent = $event"
                    (ngModelChange)="validateCalendar()"
                    [slbDateTimePicker]="dateTimePicker1"
                    [max]="maxValue"
                    [placeholder]="calendarPlaceholder"
                    matInput />
                  <slb-date-time-picker-toggle [for]="dateTimePicker1" matSuffix [disabled]="!getEnableCalendar">
                  </slb-date-time-picker-toggle>
                  <slb-date-time-picker-popup #dateTimePicker1></slb-date-time-picker-popup>
                </mat-form-field>
              </div>
              <div class="error-holder">
                <mat-error *ngIf="isRequired">Please fill the required field</mat-error>
                <mat-error *ngIf="endDateError">End date should be greater than the start date</mat-error>
                <mat-error *ngIf="currentDateError">Start date and end date should be less than the current date</mat-error>
              </div>
            </div>
            <slb-radio-button-group
              class="date-range"
              [isVertical]="true"
              (ngModelChange)="onSwitchDays($event)"
              [(ngModel)]="selectedDateRange"
              [values]="dateRange"></slb-radio-button-group>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true" class="workflow-selector-container">
            <mat-expansion-panel-header class="workflow-selector">
              <p class="workflow-header">Workflow</p>
            </mat-expansion-panel-header>
            <div class="checkbox-option" *ngFor="let item of workFlowItems; let i = index">
              <mat-checkbox [(ngModel)]="item.checked" (change)="filter(item.name, $event.checked, workFlowItems, 'workflow')">{{
                item.name
              }}</mat-checkbox>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true" class="site-selector-container">
            <mat-expansion-panel-header class="site-selector">
              <p class="site-header">Country</p>
            </mat-expansion-panel-header>
            <div class="checkbox-option" *ngFor="let item of countryList; let i = index">
              <mat-checkbox [(ngModel)]="item.checked" (change)="filter(item.name, $event.checked, countryList, 'country')">{{
                item.name
              }}</mat-checkbox>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true" class="site-selector-container">
            <mat-expansion-panel-header class="site-selector">
              <p class="site-header">Site</p>
            </mat-expansion-panel-header>
            <div class="checkbox-option" *ngFor="let item of siteListItem; let i = index">
              <mat-checkbox [(ngModel)]="item.checked" (change)="filter(item.name, $event.checked, siteListItem, 'site')">{{
                item.name
              }}</mat-checkbox>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true" class="site-selector-container">
            <mat-expansion-panel-header class="site-selector">
              <p class="site-header">Event Status</p>
            </mat-expansion-panel-header>
            <div class="checkbox-option" *ngFor="let item of eventStatusItems; let i = index">
              <mat-checkbox [(ngModel)]="item.checked" (change)="filter(item.name, $event.checked, eventStatusItems, 'event')">{{
                item.name
              }}</mat-checkbox>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true" class="site-selector-container">
            <mat-expansion-panel-header class="site-selector">
              <p class="site-header">Risk Status</p>
            </mat-expansion-panel-header>
            <div class="checkbox-option" *ngFor="let item of riskDataList; let i = index">
              <mat-checkbox [(ngModel)]="item.checked" (change)="filter(item.name, $event.checked, riskDataList, 'risk')">{{
                item.name
              }}</mat-checkbox>
            </div>
          </mat-expansion-panel>
        </div>
        <div class="footer-button">
          <button class="clear-btn" slb-secondary-button type="button" (click)="onClear()" [disabled]="isDefault">Clear</button>
          <button class="apply-btn" slb-button (click)="apply()" [disabled]="!filterValueChange">Apply</button>
        </div>
      </div>
    </div>
    <div class="chart-event-container">
      <div class="multiple-chart-container">
        <app-global-charts></app-global-charts>
      </div>
      <div class="event-container">
        <app-global-event></app-global-event>
      </div>
    </div>
  </div>
</div>
