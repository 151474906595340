import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { ADMIN_DISPLAYED_COLUMNS } from '../../shared/constants/admin-constants';
import { EmailNotificationHistory, EmailNotificationInit, EmailNotificationSave, EmailNotificationTable } from '../../shared/models/admin';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import { LoginService } from '@agora/agora-ui-library';
import { NotificationService } from '../../shared/services/notification.service';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { EMAIL_NOTIFICATION } from '../../shared/constants/admin-constants';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<EmailNotificationTable, MatTableDataSourcePaginator>;
  public displayedColumns: string[] = ADMIN_DISPLAYED_COLUMNS;
  public orgData: EmailNotificationInit[] = [];
  public historyData: EmailNotificationHistory[] = [];
  public tableData: EmailNotificationTable[] = [];
  public viewMode = EMAIL_NOTIFICATION.WITH;
  public currentZone = '';
  public isLoading = false;
  public disableSave = true;
  public isDirty = false;
  public primary = EMAIL_NOTIFICATION.PRIMARY;
  private destroyed = new Subject();

  constructor(
    private timeZoneService: TimeZoneService,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private messageService: MessageService
  ) {
    this.currentZone = this.timeZoneService.getTimeZone();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.isDirty = false;
    this.disableSave = true;
    this.notificationService
      .getOrganization()
      .pipe(
        tap((organizationData: EmailNotificationInit[]) => {
          this.orgData = organizationData;
          this.setTableData(this.orgData);
        }),
        catchError(() => {
          this.isLoading = false;
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: EMAIL_NOTIFICATION.SET_EMAIL_NOTIFICATION_ORG_ERROR,
            closable: true,
            sticky: true,
          });

          return of<EmailNotificationInit[]>({} as EmailNotificationInit[]);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public selectionChange(value: string, element: EmailNotificationTable): void {
    this.isDirty = true;
    element.emailWithMedia = value;
    element.emailWithMediaFlag = value === EMAIL_NOTIFICATION.WITH;
    element.modifiedBy = this.loginService.getUserInfo()?.name ?? '';
    this.disableSave = false;
  }

  public saveSettings(): void {
    this.isLoading = true;
    const payload: EmailNotificationSave[] = [];
    this.tableData.forEach(data => {
      if (data.modifiedBy) {
        payload.push({
          orgId: data.orgId,
          emailWithMedia: data.emailWithMedia === EMAIL_NOTIFICATION.WITH,
          createdBy: data.modifiedBy,
        });
      }
    });
    this.notificationService
      .saveEmailNotification(payload)
      .pipe(
        tap(() => {
          this.isLoading = false;
          this.isDirty = false;
          this.disableSave = true;
          this.tableData = [];
          this.setTableData(this.orgData);
          this.messageService.add({
            severity: SlbSeverity.Success,
            summary: 'Success',
            detail: EMAIL_NOTIFICATION.SET_EMAIL_NOTIFICATION_SUCCESS,
          });
        }),
        catchError(() => {
          this.isLoading = false;
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: EMAIL_NOTIFICATION.SET_EMAIL_NOTIFICATION_SAVE_ERROR,
            closable: true,
            sticky: true,
          });

          return of<EmailNotificationTable>({} as EmailNotificationTable);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public resetSettings(): void {
    this.isLoading = true;
    this.tableData = [];
    this.createTableData(this.orgData);
    this.isDirty = false;
    this.disableSave = true;
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private setTableData(orgData: EmailNotificationInit[]): void {
    this.notificationService
      .getEmailNotification()
      .pipe(
        tap((notificationData: EmailNotificationHistory[]) => {
          this.historyData = notificationData;
          this.createTableData(orgData);
        }),
        catchError(() => {
          this.isLoading = false;
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: EMAIL_NOTIFICATION.SET_EMAIL_NOTIFICATION_HIST_ERROR,
            closable: true,
            sticky: true,
          });

          return of<EmailNotificationTable[]>({} as EmailNotificationTable[]);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private createTableData(orgData: EmailNotificationInit[]): void {
    orgData.forEach(org => {
      const data = this.historyData.find(hist => hist.orgId === org.orgId);
      if (data) {
        this.tableData.push({
          name: org.name,
          orgId: org.orgId,
          emailWithMedia: data.emailWithMedia ? EMAIL_NOTIFICATION.WITH : EMAIL_NOTIFICATION.WITHOUT,
          emailWithMediaFlag: data.emailWithMedia,
          createdAt: new Date(data.createdAt),
          createdBy: data.createdBy,
        });
      } else {
        this.tableData.push({
          name: org.name,
          orgId: org.orgId,
          emailWithMedia: EMAIL_NOTIFICATION.WITH,
          emailWithMediaFlag: true,
          createdAt: null,
          createdBy: null,
        });
      }
    });
    this.dataSource = new MatTableDataSource(this.tableData);
    this.isLoading = false;
  }
}
