export const environment = {
  production: true,
  apiBaseUrl: 'https://evp-eu-hse.agoraiot.app',
  isAutomationTesting: false,
  appConfigApiUrl: '',
  identityProviderUrl: 'https://csi.slb.com/v2',
  identityProviderClientKey: '8e8151ba9fb7bd03e5085601fc957fad',
  identityProviderClientSecret: '4e648010960343049ff4fed15aa322efe2eecf2b376d',
  identityProviderRedirectUrl: 'https://evp-eu-hse.agoraiot.app/login/logincallback',
  authType: ''
};
