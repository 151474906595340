import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CameraChartData, DashabordChartSummary, GatewayChartData } from '../shared/models/dashboardChartSummary';
import { BehaviorSubject, Subject, catchError, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { Asset, AssetHierarchy } from '../shared/models/assetHierarchy';
import { DashboardAlertData, TimeZone } from '../shared/models/dashabordAlertData';
import { Camera, Gateway } from '../shared/models/gateway';
import { EventDetails, WORKFLOW_DETAILS } from '../shared/models/eventDetails';
import { TIMEZONE } from '../shared/constants/timezone-constant';
import { DashboardConstants } from '../shared/constants/dashboard-constants';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { PageEvent } from '@angular/material/paginator';
import { AlertFilter } from '../shared/models/alertFilter';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { CameraProfileService } from '../shared/services/camera-profile.service';
import { TimeZoneService } from '../shared/services/time-zone.service';
import { DashboardService } from '../shared/services/dashboard.service';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import { BARCHARTOPTIONS, LINECHARTOPTIONS } from '../shared/constants/chart-constant';
import { EventChart } from '../shared/models/eventChart';
import { DONUT_SERIES, EVENT_STATUS_CHART_OPTION, EVENTCOLORS, WORKFLOW } from '../shared/constants/camera-profile-constant';
import { countryList } from '../shared/models/dashabordEventDetails';
import { GlobalViewService } from '../shared/services/global-view.service';
import { WorkFlow } from '../shared/models/global-view';

noData(Highcharts);
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public rigData: Gateway[];
  public viewMode = DashboardConstants.defaultView;
  public range = DashboardConstants.defaultTimeRange;
  public popOverCameraDetails: EventDetails | null;
  public workflowList: WORKFLOW_DETAILS[] = [];
  public rigList: Asset[] = [];
  public status = DashboardConstants.statusList;
  public timezoneList = TIMEZONE;
  public countryList: string[] = [];
  public currentZone = '';
  public currentZoneArea = '';
  public currentZoneDetails!: TimeZone;
  public currentCountry = '';
  public countryName = '';
  public totalRig = 0;
  public totalGateway = 0;
  public totalCamera = 0;
  public totalVoilation = 0;
  public alertData: DashboardAlertData;
  public statusList = DashboardConstants.defaultStatusList;
  public summaryLoader = false;
  public alertsLoader = false;
  public dashboardChartData: DashabordChartSummary;
  public alertFilter: AlertFilter = JSON.parse(JSON.stringify(DashboardConstants.defaultFilter));
  public expandedIndex = 0;
  public isFilter = true;
  public hasPrevious = false;
  public hasNext = false;
  public selectedPopOverIndex = -1;
  public sessionToken: string;
  public Highcharts = Highcharts;
  public deviceStatusOptions: any;
  public eventChartOptions: Highcharts.Options;
  public updateChart: boolean;
  public eventType = 'none';
  public totalEvents = 0;
  public eventLineChartOption: Highcharts.Options;
  public updateEventLineChart: boolean;
  public showDeviceOption: boolean;
  public disableCountry: boolean;
  public workflows = WORKFLOW;
  private showAllEvents: string;
  private destroyed = new Subject();
  private currentRig: Gateway;
  private timeZone: string;
  private summaryState = new BehaviorSubject(true);
  private summaryState$ = this.summaryState.asObservable();
  private workFlowData: WorkFlow[];

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private cameraProfileService: CameraProfileService,
    private timeZoneService: TimeZoneService,
    private globalService: GlobalViewService
  ) {
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((timeZoneDetails: TimeZone) => {
          this.currentZone = timeZoneDetails.value;
          this.currentZoneArea = timeZoneDetails?.label;
          this.currentZoneDetails = timeZoneDetails;
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.summaryLoader = true;
    this.alertsLoader = true;
    this.updateChart = false;
    this.updateEventLineChart = false;
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    const expandedIndex = sessionStorage.getItem('rigAccordionIndex');
    this.expandedIndex = isNaN(parseInt(expandedIndex ?? '0', 10)) ? 0 : parseInt(expandedIndex ?? '0', 10);
    this.viewMode = sessionStorage.getItem('viewMode') ?? this.viewMode;
    this.timeZone = sessionStorage.getItem('timezone') ?? '';
    this.cameraProfileService.resetAnalyticsSelection();
    this.eventChartOptions = JSON.parse(JSON.stringify(EVENT_STATUS_CHART_OPTION));
    this.deviceStatusOptions = JSON.parse(JSON.stringify(BARCHARTOPTIONS));
    this.eventLineChartOption = JSON.parse(JSON.stringify(LINECHARTOPTIONS));
    this.showDeviceOption = false;
    this.getCountryData();
  }

  public switchView(event: any): void {
    this.viewMode = event?.value;
    sessionStorage.setItem('viewMode', this.viewMode);
  }

  public openCameraProfilePage(camera: Camera): void {
    let gatewayDetails = '';
    let rigName = '';
    let eventDate = '';
    this.rigData.filter(data => {
      const selectedCamera = data.cameras.find(cameradetails => cameradetails.controllerId === camera.controllerId);
      const isGateway = selectedCamera;
      if (isGateway) {
        gatewayDetails = data.gatewayId;
        rigName = data.rigName;
        sessionStorage.setItem('gatewayStatus', data.gatewayStatus.toString());
        if (rigName.includes(' ')) {
          rigName = rigName.split(' ').join('*');
        }
        if (selectedCamera?.eventDetails && selectedCamera?.eventDetails?.timestamp) {
          eventDate = selectedCamera?.eventDetails?.timestamp;
          sessionStorage.setItem('selectedCameraEventDate', eventDate);
          this.cameraProfileService.setEventTime(eventDate);
        }
      }
    });
    this.router.navigate(['home', camera.name, camera.cameraStatus, gatewayDetails, camera.controllerId, rigName]);
  }

  public onImageClick(camera: Camera, index: number, rig: Gateway): void {
    if (camera?.eventDetails) {
      camera.eventDetails.rigName = rig.rigName;
      this.eventType = 'none';
      this.selectedPopOverIndex = index;
      this.currentRig = rig;
      this.checkHasNext(index + 1, true);
      this.checkHasPrevious(index - 1, true);
      const data: EventDetails = camera?.eventDetails ?? ({} as EventDetails);
      data.isVideo = true;
      this.updateLatestPopoverDetails(camera);
    } else {
      this.showNoEventToasterMesaage(camera);
    }
  }

  public onEventClick(event: number, isClick: boolean): void {
    if (event > this.selectedPopOverIndex) {
      this.eventType = 'next';
    } else if (event < this.selectedPopOverIndex) {
      this.eventType = 'prev';
    }
    this.selectedPopOverIndex = event;
    this.checkHasNext(event, isClick);
    this.checkHasPrevious(event, isClick);
    if (this.currentRig.cameras[this.selectedPopOverIndex]) {
      const camera: Camera = this.currentRig.cameras[this.selectedPopOverIndex];
      if (camera.eventDetails) {
        this.updateLatestPopoverDetails(camera);
      }
    }
  }

  public updateLatestPopoverDetails(camera: Camera): void {
    const data: EventDetails = camera.eventDetails ?? ({} as EventDetails);
    data.isVideo = true;
    data.cameraStatus = camera.cameraStatus;
    data.equipmentName = camera.name;
    data.gatewayStatus = this.currentRig.gatewayStatus;
    this.popOverCameraDetails = data;
  }

  public filterAlertList(): void {
    this.isFilter = true;
    sessionStorage.setItem('rigState', JSON.stringify(this.alertFilter));
    this.getAlertList();
  }

  public onFilterChange(): void {
    this.isFilter = false;
  }

  public setViolationChartData(chartData: { [keys: string]: number }): void {
    if (chartData) {
      this.eventChartOptions.series = [];
      const options: Highcharts.PointOptionsObject[] = [];
      Object.keys(chartData).map((key: string) => {
        if (key !== 'total' && chartData[key] !== 0 && chartData[key] !== null) {
          const label = this.formatKey(key);
          const color = EVENTCOLORS.filter(tag => tag.name === label)[0] ? EVENTCOLORS.filter(tag => tag.name === label)[0].color : '';
          options.push({
            name: label,
            y: chartData[key],
            color,
            fillColor: color,
          });
        }
      });
      const series: Highcharts.SeriesPieOptions = JSON.parse(JSON.stringify(DONUT_SERIES));
      series.data = options;
      this.eventChartOptions.series?.push(series);
      this.totalVoilation = chartData.total;
    }
    this.updateChart = true;
  }

  public setAssertHierarchy(data: AssetHierarchy): void {
    if (data) {
      this.rigList = data?.rigs;
      const workflow: string[] = [];

      this.rigList.forEach(rig => {
        rig?.workflows?.forEach(x => {
          if (!workflow.includes(x)) {
            const value = WORKFLOW.filter(flow => flow.value?.toLowerCase() === x?.toLowerCase());
            workflow.push(x);
            this.workflowList.push(...value);
          }
          this.workflowList?.sort((a, b) => (a.label < b.label ? 1 : -1));
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  public onTimezoneChange(): void {
    this.timeZoneService.setSelectedTimeZone(this.currentZoneDetails);
  }

  public onCountryChange(): void {
    sessionStorage.setItem('country', this.countryName);
    this.alertFilter = JSON.parse(JSON.stringify(DashboardConstants.defaultFilter));
    this.disableCountry = true;
    this.summaryLoader = true;
    this.alertsLoader = true;
    this.expandedIndex = 0;
    sessionStorage.setItem('rigAccordionIndex', '0');
    this.summaryState.next(true);
  }

  public onPageFired(event: PageEvent): void {
    this.alertFilter.pageNumber = event.pageIndex + 1;
    this.alertFilter.recordsPerPage = event.pageSize;
    sessionStorage.setItem('pageNumber', (event.pageIndex + 1).toString());
    sessionStorage.setItem('pageSize', event.pageSize.toString());
    this.getAlertList();
  }

  public getExpandedPanel(index: number): void {
    sessionStorage.setItem('rigAccordionIndex', index.toString());
  }

  public getDateFormateString(date: string): string {
    let displayTime = '';
    if (date) {
      const currentDate = moment();
      const eventDate = moment(date);
      if (moment.duration(currentDate.diff(eventDate)).asHours() <= 3) {
        displayTime = 'Recently';
      } else if (moment(currentDate, 'DD-MM-YYYY').isSame(moment(eventDate, 'DD-MM-YYYY'), 'day')) {
        displayTime = 'Today';
      } else if (moment(currentDate.subtract(1, 'days'), 'DD-MM-YYYY').isSame(moment(eventDate, 'DD-MM-YYYY'), 'day')) {
        displayTime = 'Yesterday';
      } else {
        displayTime = 'Over 48 hours ago ' + this.datePipe.transform(date, 'dd/MM/YY HH:mm:ss a', this.currentZone);
      }
    }

    return displayTime;
  }

  public showNoEventToasterMesaage(camera: Camera): void {
    this.messageService.add({ severity: SlbSeverity.Info, summary: `${DashboardConstants.NOCAMERAEVENT} ${camera.name}` });
  }

  public getWorkflowIcon(workflow: string): string {
    let icon = '';
    if (workflow) {
      icon = this.dashboardService.getIcon(workflow);
    }

    return icon;
  }

  private setEventLineChartData(chartData: EventChart): void {
    const eventSeries: Highcharts.SeriesOptionsType[] = [];
    let totalEvents = 0;
    DashboardConstants.EVENTTYPE.map(type => {
      const seriesData: Highcharts.PointOptionsObject[] = [];
      chartData?.event?.map(e => {
        const eventByType = e.eventTotal.find(ev => this.checkType(type, ev.eventType));
        if (eventByType) {
          seriesData.push({
            y: eventByType.total,
            x: new Date(e.date + ' 00:00').getTime(),
          } as Highcharts.PointOptionsObject);
          totalEvents += eventByType.total;
        }
      });
      if (seriesData.length > 0) {
        eventSeries.push({
          lineWidth: 2,
          type: 'line',
          name: type,
          color: EVENTCOLORS.filter(tag => this.checkType(type, tag.name))[0]
            ? EVENTCOLORS.filter(tag => this.checkType(type, tag.name))[0].color
            : '',
          data: seriesData,
          visible: true,
        } as Highcharts.SeriesOptionsType);
      }
    });
    this.eventLineChartOption.series = eventSeries;
    this.totalEvents = totalEvents;
    this.updateEventLineChart = true;
    this.summaryLoader = false;
  }

  private getCountryData(): void {
    this.dashboardService
      .getCountryData()
      .pipe(
        tap((countryData: countryList) => {
          this.resetFilter();
          this.countryList = countryData.country;
          sessionStorage.setItem('isAdmin', countryData.isAdmin.toString());
          this.cameraProfileService.setAdmin(countryData.isAdmin.toString());
          this.getSummaryData();
        }),
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: DashboardConstants.dashabordCountryAPIError,
            closable: true,
            sticky: true,
          });
          this.alertsLoader = false;
          this.summaryLoader = false;

          return of<string[]>([] as string[]);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private getSummaryData(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    this.summaryState$
      .pipe(
        switchMap(() =>
          this.globalService.getWorkFlowId().pipe(
            tap(workflow => {
              this.workFlowData = workflow;
            }),
            switchMap(() =>
              this.cameraProfileService.eventData$.pipe(
                tap(() => {
                  this.summaryLoader = true;
                  this.alertsLoader = true;
                  this.showAllEvents = sessionStorage.getItem('showAllEvents') || '';
                }),
                switchMap(() =>
                  this.dashboardService
                    .getDashabordSummaryData(this.countryName, startDate.toISOString(), endDate.toISOString(), this.showAllEvents)
                    .pipe(
                      catchError(() => {
                        this.disableCountry = false;
                        this.messageService.add({
                          severity: SlbSeverity.Error,
                          summary: DashboardConstants.dashabordChartAPIError,
                          closable: true,
                          sticky: true,
                        });
                        this.summaryLoader = false;
                        this.alertsLoader = false;

                        return of<DashabordChartSummary>({} as DashabordChartSummary);
                      }),
                      tap((data: DashabordChartSummary) => {
                        if (data) {
                          const expandedIndex = sessionStorage.getItem('rigAccordionIndex') ?? '0';
                          this.expandedIndex = parseInt(expandedIndex ?? '0', 10);
                          this.setDeviceStatus(data?.gateways, data?.cameras);
                          this.setViolationChartData(data?.violations);
                        }
                        this.getEventTrendData(startDate, endDate);
                        this.getAssetHierarchy();
                      }),
                      takeUntil(this.destroyed)
                    )
                ),
                takeUntil(this.destroyed)
              )
            )
          )
        )
      )
      .subscribe();
  }

  private getAlertList(): void {
    const filteredList: AlertFilter = JSON.parse(JSON.stringify(this.alertFilter));

    this.dashboardService
      .getAlertData(this.countryName, filteredList, this.showAllEvents)
      .pipe(
        catchError(() => {
          this.disableCountry = false;
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: DashboardConstants.dashabordAlertsAPIError,
            closable: true,
            sticky: true,
          });
          this.alertsLoader = false;

          return of<DashboardAlertData>({} as DashboardAlertData);
        }),
        tap((assetHierarchy: DashboardAlertData) => {
          if (assetHierarchy) {
            this.alertData = assetHierarchy;
            this.totalRig = assetHierarchy.totalRecords;
            this.rigData = assetHierarchy.data;
            this.rigData?.forEach(gateways => {
              gateways.cameras.forEach(camera => {
                if (camera?.eventDetails !== null) {
                  camera.eventDetails.rigState = this.dashboardService.getRigEventDetails(camera.eventDetails);
                  camera.eventDetails.buLogic =
                    camera?.eventDetails.buLogic !== null ? Number(camera?.eventDetails.buLogic) : DashboardConstants.BULOGIC_2;
                  camera.eventDetails.icon = this.dashboardService.getIconImg(camera.eventDetails);
                  camera.eventDetails.label = this.dashboardService.getReason(camera.eventDetails);
                  if (gateways.gatewayStatus === 2) {
                    gateways.rigDataStatus = 1;
                    camera.cameraStatus = 2;
                  }
                }
              });
            });
            if (this.selectedPopOverIndex !== -1) {
              const camera = this.rigData[this.expandedIndex].cameras[this.selectedPopOverIndex];
              this.onImageClick(camera, this.selectedPopOverIndex, this.rigData[this.expandedIndex]);
            }
          }
          this.alertsLoader = false;
          this.disableCountry = false;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private getAssetHierarchy(): void {
    this.dashboardService
      .getAssetHirarchy(this.countryName)
      .pipe(
        catchError(() => {
          this.disableCountry = false;
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: DashboardConstants.dashabordAssetHierarchyAPIError,
            closable: true,
            sticky: true,
          });
          this.alertsLoader = false;

          return of<AssetHierarchy>({} as AssetHierarchy);
        }),
        tap((assetHierarchy: AssetHierarchy) => {
          if (assetHierarchy) {
            this.workflowList = [];
            this.rigList = [];
            this.setAssertHierarchy(assetHierarchy);
            this.getAlertList();
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private resetFilter(): void {
    const pageIndex = sessionStorage.getItem('pageNumber');
    const pageSize = sessionStorage.getItem('pageSize');
    const alertFilter = sessionStorage.getItem('rigState');
    this.alertFilter = alertFilter ? JSON.parse(alertFilter) : JSON.parse(JSON.stringify(DashboardConstants.defaultFilter));
    this.alertFilter.pageNumber = pageIndex ? parseInt(pageIndex, 10) : DashboardConstants.PAGE_NUMBER;
    this.alertFilter.recordsPerPage = pageSize ? parseInt(pageSize, 10) : DashboardConstants.RECORDS_PER_PAGE;
    const countryName = sessionStorage.getItem('country');
    this.countryName = countryName ? countryName : '';
  }

  private setDeviceStatus(gateway: GatewayChartData, camera: CameraChartData): void {
    if (gateway && camera) {
      this.deviceStatusOptions.series = [
        {
          name: 'Disconnected',
          data: [gateway.disconnected, camera.disconnected],
          color: '#939393',
        },
        {
          name: 'Inactive',
          data: [gateway.inactive, camera.inactive],
          color: '#F43A20',
        },
        {
          name: 'Active',
          data: [gateway.active, camera.active],
          color: '#008DA9',
        },
      ];
      this.showDeviceOption = gateway.total > 0;
      this.updateChart = true;
    }
  }

  private checkHasNext(index: number, isClick: boolean): void {
    const currentIndex = isClick ? index : this.selectedPopOverIndex;
    const hasNext = currentIndex >= this.currentRig.cameras.length ? false : true;
    if (hasNext) {
      for (let i = currentIndex; i < this.currentRig.cameras.length; i++) {
        this.hasNext = i >= this.currentRig.cameras.length ? false : true;
        if (this.currentRig.cameras[i]?.eventDetails) {
          this.hasNext = i >= this.currentRig.cameras.length - 1 ? false : true;
          if (!isClick && this.eventType === 'next') {
            this.selectedPopOverIndex = i;
          } else {
            this.hasNext = true;
          }
          break;
        } else {
          this.hasNext = false;
        }
      }
    } else {
      if (isClick) {
        this.hasNext = currentIndex >= this.currentRig.cameras.length ? false : true;
      } else {
        this.hasNext = false;
      }
    }
  }

  private checkHasPrevious(index: number, isClick: boolean): void {
    const currentIndex = isClick ? index : this.selectedPopOverIndex;
    const hasPrevious = currentIndex <= 0 ? false : true;
    if (hasPrevious) {
      for (let i = currentIndex; i >= 0; i--) {
        if (i >= 0) {
          if (this.currentRig.cameras[i]?.eventDetails) {
            this.hasPrevious = i <= 0 ? false : true;
            if (!isClick && this.eventType === 'prev') {
              this.selectedPopOverIndex = i;
            } else {
              if (isClick) {
                this.hasPrevious = i < 0 ? false : true;
              }
            }
            break;
          } else {
            this.hasPrevious = false;
          }
        }
      }
    } else {
      if (isClick) {
        this.hasPrevious = currentIndex < 0 ? false : true;
      } else {
        this.hasPrevious = false;
      }
    }
  }

  private getEventTrendData(startDate: Date, endDate: Date): void {
    this.dashboardService
      .getDashboardEventTrendingData(this.countryName, startDate.toISOString(), endDate.toISOString(), this.timeZone, this.showAllEvents)
      .pipe(
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: DashboardConstants.dashabordChartAPIError,
            closable: true,
            sticky: true,
          });
          this.summaryLoader = false;
          this.alertsLoader = false;
          this.disableCountry = false;

          return of<EventChart>({} as EventChart);
        }),
        tap((data: EventChart) => {
          if (data) {
            this.setEventLineChartData(data);
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private checkType(type: string, eventType: string): boolean {
    return type.split(' ').join('').toLowerCase().includes(eventType.toLowerCase());
  }

  private formatKey(key: string): string {
    key.replace(key[0], key[0].toUpperCase());
    const splitBasedonUpperCase = key.replace(key[0], key[0].toUpperCase()).match(/[A-Z][a-z]+/g);
    if (splitBasedonUpperCase?.length) {
      return splitBasedonUpperCase.join(' ');
    }

    return '';
  }
}
