import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from '../models/eventDetails';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @Input({ required: true }) media: Media[] = [];
  @Input({ required: true }) showIndicators = true;
  @Input({ required: true }) selectedIndex = 0;
  @Input({ required: true }) showControls = true;
  @Input('isImgLoad') isImgLoad: Subject<boolean>;

  @Output() activeIndex = new EventEmitter<number>();
  public sessionToken: string;
  public fullScreenImgSrc: string;
  public isFullscreen = false;
  public imageLoader = true;

  constructor() {
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    this.isFullscreen = false;
    this.fullScreenImgSrc = '';
  }

  ngOnInit(): void {
    if (this.isImgLoad) {
      this.isImgLoad.subscribe(() => {
        this.imageLoader = true;
      });
    }
  }

  public setSelectedIndex(index: number): void {
    this.selectedIndex = index;
    this.activeIndex.emit(this.selectedIndex);
  }

  public onImageLoad(): void {
    setTimeout(() => {
      this.imageLoader = false;
    }, 2000);
  }

  public showImageInFullscreen(imgSrc: string): void {
    this.fullScreenImgSrc = imgSrc + '/?token=' + this.sessionToken;
    this.isFullscreen = true;
  }

  public hideImageInFullscreen(): void {
    this.fullScreenImgSrc = '';
    this.isFullscreen = false;
  }

  onPrevClick(): void {
    this.imageLoader = true;

    if (this.selectedIndex !== 0) {
      this.selectedIndex--;
      this.activeIndex.emit(this.selectedIndex);
    }
    setTimeout(() => {
      this.imageLoader = false;
    }, 2000);
  }

  onNextClick(): void {
    this.imageLoader = true;

    if (this.selectedIndex !== this.media.length - 1) {
      this.selectedIndex++;
      this.activeIndex.emit(this.selectedIndex);
    }
    setTimeout(() => {
      this.imageLoader = false;
    }, 2000);
  }
}
