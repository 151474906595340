import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { catchError, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DashboardConstants } from '../../shared/constants/dashboard-constants';
import { NOTIFICATION } from '../../shared/constants/notification-constant';
import { CheckBoxList, TrendNotification } from '../../shared/models/notification';
import { EquipmentWorkflow, Notification } from '../../shared/models/notification';
import { GlobalViewService } from '../../shared/services/global-view.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  public paginatedData: PageEvent = JSON.parse(JSON.stringify(DashboardConstants.DEFAULT_PAGINATION));
  public viewMode: string;
  public isChecked: boolean;
  public isSystemOpen: boolean;
  public isTrendOpen: boolean;
  public isSystem: boolean;
  public isTrend: boolean;
  public minMaxData = NOTIFICATION.MIN_MAX_SELECT;
  public displayColumns = NOTIFICATION.TREND_TABLE_COLUMN;
  public dataSource: MatTableDataSource<TrendNotification>;
  public isCameraFunction: boolean;
  public isAutomationControl: boolean;
  public systemData: CheckBoxList[];
  public notificationType: CheckBoxList[];
  public notificationData: TrendNotification[];
  public totalRecords = 0;
  public isLoading = false;
  public isError = false;
  public minInfo = NOTIFICATION.MIN_INFO;
  public maxInfo = NOTIFICATION.MAX_INFO;
  public lastUpdated: string;
  public disableApplyBtn = true;
  public disableResetBtn = true;

  private workFlow: CheckBoxList[];
  private getData: Notification;
  private equipmentList: EquipmentWorkflow[];
  private destroyed = new Subject();
  private defaultViewmode: string;

  constructor(
    private notificationService: NotificationService,
    private messageService: MessageService,
    private globalService: GlobalViewService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isSystemOpen = true;
    this.isTrendOpen = true;
    this.isLoading = true;
    this.getInitialData();
    this.resetData();
  }

  public switchView(event: { value: string }): void {
    this.enableBtn();
    this.viewMode = event.value;
  }

  public enableBtn(): void {
    this.disableApplyBtn = false;
    this.disableResetBtn = false;
  }

  public system(): void {
    if (!this.isSystem) {
      this.isAutomationControl = false;
      this.isCameraFunction = false;
    } else {
      this.isAutomationControl = true;
      this.isCameraFunction = true;
    }
  }

  public setSystemData(): void {
    if (!this.isAutomationControl && !this.isCameraFunction) {
      this.isSystem = false;
    }
  }

  public save(): void {
    this.isLoading = true;

    const tableData: TrendNotification[] = JSON.parse(JSON.stringify(this.dataSource.data));
    const saveData: Notification = JSON.parse(JSON.stringify(this.getData));
    saveData.cameraNotificationId = this.isCameraFunction
      ? this.systemData.filter(sys => sys.name.includes(NOTIFICATION.CAMERA_NOT_FUNCTION))?.[0]?.id
      : null;
    saveData.witsNotificationId = this.isAutomationControl
      ? this.systemData.filter(sys => sys.name.includes(NOTIFICATION.CAMERA_NOT_FUNCTION))?.[0]?.id
      : null;
    saveData.notificationTypeMasterId = this.viewMode;
    saveData.createdAt = new Date().toISOString();
    if (this.isTrend) {
      saveData.notificationTrend = tableData.map(data => {
        data.min = data.min === NOTIFICATION.NOT_APPLICABLE ? '0' : data.min;
        data.max = data.max === NOTIFICATION.NOT_APPLICABLE ? '0' : data.max;

        return data;
      });
    } else {
      saveData.notificationTrend = [];
    }

    this.notificationService
      .saveUserNotification(saveData)
      .pipe(
        tap(() => {
          this.lastUpdated = new Date().toISOString();
          this.isLoading = false;
          this.disableApplyBtn = true;
          this.messageService.add({
            severity: SlbSeverity.Success,
            summary: NOTIFICATION.SET_NOTIFICATION_SUCCESS,
          });
        }),
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: NOTIFICATION.SET_NOTIFICATION_ERROR,
            closable: true,
            sticky: true,
          });
          this.isLoading = false;

          return of<Notification>({} as Notification);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public resetData(): void {
    this.disableResetBtn = true;
    this.isSystem = true;
    this.isTrend = false;
    this.isAutomationControl = true;
    this.isCameraFunction = true;
    this.isError = false;
    this.viewMode = this.defaultViewmode;
    this.dataSource?.data?.forEach(data => {
      data.min = NOTIFICATION.NOT_APPLICABLE;
      data.max = NOTIFICATION.NOT_APPLICABLE;
      data.noNotification = true;
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private getInitialData(): void {
    this.notificationService
      .getSystemData()
      .pipe(
        tap((systemData: CheckBoxList[]) => {
          this.systemData = systemData;
        }),
        catchError(() => {
          return this.errorMessage(NOTIFICATION.SYSTEM_DATA_ERROR);
        }),
        switchMap(() =>
          this.globalService.getWorkFlowId().pipe(
            tap((workFlowData: CheckBoxList[]) => {
              this.workFlow = workFlowData;
            }),
            catchError(() => {
              return this.errorMessage(NOTIFICATION.GET_WORKFLOW_ERROR);
            })
          )
        ),
        switchMap(() =>
          this.notificationService.getNotificationType().pipe(
            tap((notificationType: CheckBoxList[]) => {
              this.notificationType = notificationType;
              this.viewMode = notificationType?.[1]?.id;
              this.defaultViewmode = notificationType?.[1]?.id;
            }),
            catchError(() => {
              return this.errorMessage(NOTIFICATION.NOTIFICATION_TYPE_ERROR);
            })
          )
        ),
        switchMap(() =>
          this.notificationService.getEquipment().pipe(
            tap((equipmentList: EquipmentWorkflow[]) => {
              this.equipmentList = equipmentList;
              this.createNotification();
            }),
            catchError(() => {
              this.messageService.add({
                severity: SlbSeverity.Error,
                summary: NOTIFICATION.GET_WORKFLOW_ERROR,
                closable: true,
                sticky: true,
              });
              this.isLoading = false;

              return of<EquipmentWorkflow[]>({} as EquipmentWorkflow[]);
            })
          )
        ),
        switchMap(() => this.getUserData()),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private getUserData(): Observable<Notification> {
    return this.notificationService.getUserNotification().pipe(
      tap((notificationData: Notification) => {
        this.getData = notificationData;
        if (notificationData.notificationTypeMasterId !== null) {
          this.setData();
        }
        this.isLoading = false;

        return of(notificationData);
      }),
      catchError(() => {
        this.messageService.add({
          severity: SlbSeverity.Error,
          summary: NOTIFICATION.GET_NOTIFICATION__ERROR,
          closable: true,
          sticky: true,
        });
        this.isLoading = false;

        return of<Notification>({} as Notification);
      })
    );
  }

  private createNotification(): void {
    this.notificationData = [];
    this.isSystem = true;
    this.isTrend = false;
    this.isCameraFunction = true;
    this.isAutomationControl = true;
    this.equipmentList.forEach(data => {
      this.notificationData.push({
        equipmentId: data.equipmentId,
        equipmentName: data.equipmentName,
        workflowId: data.workFlowId,
        workflowName: data.workFlowName,
        min: NOTIFICATION.NOT_APPLICABLE,
        max: NOTIFICATION.NOT_APPLICABLE,
        noNotification: true,
      });
    });
    this.totalRecords = this.notificationData.length;
    this.dataSource = new MatTableDataSource(this.notificationData);
  }

  private setData(): void {
    this.isCameraFunction = this.getData.cameraNotificationId !== null;
    this.isAutomationControl = this.getData.witsNotificationId !== null;
    this.isSystem = this.isCameraFunction || this.isAutomationControl;
    this.viewMode = this.getData.notificationTypeMasterId;
    this.isTrend = this.getData?.notificationTrend?.length !== 0;
    this.lastUpdated = this.getData.createdAt;
    this.notificationData.forEach(notification => {
      const getNotifiedData = this.getData?.notificationTrend?.filter(data => {
        return data.equipmentId === notification.equipmentId && data.workflowId === notification.workflowId;
      })?.[0];
      if (getNotifiedData) {
        notification.min = !getNotifiedData.min ? NOTIFICATION.NOT_APPLICABLE : getNotifiedData.min.toString();
        notification.max = !getNotifiedData.max ? NOTIFICATION.NOT_APPLICABLE : getNotifiedData.max.toString();
        notification.noNotification = getNotifiedData.noNotification;
      }
    });
    this.dataSource = new MatTableDataSource(this.notificationData);
  }

  private errorMessage(message: string): Observable<CheckBoxList[]> {
    this.messageService.add({
      severity: SlbSeverity.Error,
      summary: message,
      closable: true,
      sticky: true,
    });
    this.isLoading = false;

    return of<CheckBoxList[]>({} as CheckBoxList[]);
  }
}
