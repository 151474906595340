<div class="profile-wrappar" *ngIf="profileData.gatewayName">
  <p>{{ profileData.gatewayName }}<span class="separator">|</span></p>
  <p>Camera location: {{ profileData.cameraLocation }}<span class="separator">|</span></p>
  <p class="icon-wrappar">
    <span>
      <mat-icon
        svgIcon="oil-bitumen"
        class="status-title"
        [ngClass]="profileData.rigDataStatus && profileData.rigDataStatus === 1 ? 'disconnected-icon' : 'active-icon'"
        id="vi_cameraprofile_active-icon"></mat-icon>
      <span class="icon-detail"
        >Rig data ({{
          profileData.rigSourceType === 0
            ? 'WITS'
            : profileData.rigSourceType === 1
            ? 'GN5'
            : profileData.rigSourceType === 2
            ? 'WITS/GN5'
            : ''
        }})</span
      >
    </span>
  </p>
  <p class="icon-wrappar" *ngIf="profileData.workflow.includes('redzone')">
    <mat-icon
      svgIcon="oil-bitumen"
      class="status-title"
      [ngClass]="{
        disconnected: profileData.smartRedZone === 0,
        static: profileData.smartRedZone === 1,
        dynamic: profileData.smartRedZone === 2
      }"></mat-icon>
    <span class="icon-detail">
      <span class="red-zone-label">Red zone:</span>
      {{
        profileData.smartRedZone === 0
          ? 'Disabled'
          : profileData.smartRedZone === 1
          ? 'Static'
          : profileData.smartRedZone === 2
          ? 'Dynamic'
          : ''
      }}
    </span>
  </p>
</div>
