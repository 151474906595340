export class EventReviewConstants {
  public static FLAG = [
    {
      id: '2a080794-f300-47a7-bca5-cd50ee9e4e67',
      name: 'Lost time injuries',
    },
    {
      id: '54548c14-a89a-4b22-8fed-f609698edcda',
      name: 'Fatality',
    },
    {
      id: 'a3da7d94-1bf0-4789-9e60-a969a5c94aff',
      name: 'Minor incident',
    },
    {
      id: 'cc6342fe-3e12-451c-beac-7901cc66e4ca',
      name: 'Near miss',
    },
  ];
  public static CATEGORY = [
    {
      id: '0f0e6d41-233b-4479-8bcc-4f83f4bb7c47',
      name: 'Yet to be reviewed',
    },
    {
      id: '77f051f3-aa6e-4371-a5c4-71ea88f8a3f3',
      name: 'Reviewed, as system detected',
    },
    {
      id: '80b654e4-87c9-4dff-b963-6229d79b92f4',
      name: 'Reviewed, human behavior',
    },
    {
      id: 'fd8b5fbd-1178-47fa-83cf-d2b680a7c081',
      name: 'Reviewed, invalid',
    },
    {
      id: '871e2d9f-e0a0-43bb-bc10-815d1cfcb97f',
      name: 'Exclude',
    },
  ];
  public static successMessage = 'Saved Succesfully!';
  public static errorMessage = 'Error occured while saving event review.';
}
