import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../shared/services/dashboard.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unsubscribed.component.html',
  styleUrls: ['./unsubscribed.component.scss'],
})
export class UnsubscribedComponent implements OnInit {
  public email_text: string;
  public org_id: number;
  private destroyed = new Subject();
  constructor(private route: ActivatedRoute, private dashboardService: DashboardService) {
    this.route.params
      .pipe(
        tap(params => {
          if (params) {
            this.email_text = params.useremail;
            this.org_id = params.orgid as number;
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnInit(): void {
    if (this.email_text && this.org_id) {
      this.dashboardService
        .unsubscribeEmail(this.email_text as string, this.org_id as number)
        .pipe(
          tap((result: string) => {
            if (result && result.toString() === 'true') {
              this.email_text = 'You have successfully unsubscribed the email.';
            } else {
              this.email_text = 'Error, while unsubscrbing the email. Please try again later.';
            }
          })
        )
        .subscribe();
    }
  }
}
