import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TIMEZONE } from '../constants/timezone-constant';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { TimeZone } from '../models/dashabordAlertData';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneService {
  public timeZoneDetails$: Observable<TimeZone>;

  private timeZoneDetailsSource = new BehaviorSubject<TimeZone>({} as TimeZone);
  private timeZoneList = TIMEZONE;
  private timeOffsetInMins: number;
  private currentArea: string;

  constructor() {
    this.setInitialValue();
    this.timeZoneDetails$ = this.timeZoneDetailsSource.asObservable();
  }

  public getDateandTime(): Date {
    const timeZone = this.getTimeZone();
    this.timeOffsetInMins = this.getTimeZoneOffsetInMinutes(timeZone);
    const parsedDate = new Date(new Date().toUTCString().slice(0, 25)).setMinutes(new Date().getUTCMinutes() - this.timeOffsetInMins);

    return new Date(parsedDate);
  }

  public getTimeZone(): string {
    const tzValue = this.timeZoneDetailsSource.value;

    return tzValue?.isDst ? tzValue.dtTimezone : tzValue.timezone;
  }

  public setSelectedTimeZone(tzValue: TimeZone): void {
    const isDst = momentTz.tz(moment().format('YYYY-MM-DD HH:mm a'), 'YYYY-MM-DD HH:mm a', tzValue.area[0]).isDST();
    tzValue.isDst = isDst;
    this.timeZoneDetailsSource.next(tzValue);
    const area = tzValue.area.includes(this.currentArea) ? this.currentArea : tzValue.area[0];
    sessionStorage.setItem('timezoneArea', area);
    sessionStorage.setItem('timezone', isDst ? tzValue.dtTimezone : tzValue.timezone);
  }

  private setInitialValue(): void {
    if (sessionStorage.getItem('timezoneArea')) {
      this.currentArea = sessionStorage.getItem('timezoneArea') ?? '';
    } else {
      this.currentArea = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    let timeZone = this.timeZoneList.find((tz: TimeZone) => tz.area.includes(this.currentArea));
    this.currentArea = timeZone ? this.currentArea : this.timeZoneList[45].area[0];
    timeZone = timeZone ? timeZone : this.timeZoneList[45]; // if timezone not found set UTC+00 as default.
    if (timeZone) {
      this.setSelectedTimeZone(timeZone);
    }
  }

  private getTimeZoneOffsetInMinutes(timeOffset: string): number {
    timeOffset = timeOffset.replace('UTC', '').trim();
    timeOffset = timeOffset.replace(':', '').trim();

    const mutiplier = timeOffset.charAt(0) === '+' ? -1 : 1;
    const hours = Number(timeOffset.slice(1, 3));
    const minutes = Number(timeOffset.slice(3, 5));

    let offsetValue = hours * 60 + minutes;
    offsetValue = offsetValue * mutiplier;

    return offsetValue;
  }
}
