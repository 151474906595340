import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { ZoneSettingOptions } from '../models/zoneSetting';

export class ZoneSettingsConstants {
  public static REDZONETYPE_RADIO_OPTIONS: RadioButtonValue[] = [
    {
      value: 0,
      name: 'Disabled',
      isDefault: false,
    },
    {
      value: 1,
      name: 'Static',
      isDefault: false,
    },
    {
      value: 2,
      name: 'Dynamic',
      isDefault: false,
    },
  ];

  public static bu1: ZoneSettingOptions[] = [
    {
      label: 'Block moving down - hook speed',
      unit: '(m/s)',
      tagname: 'TrippingInHookSpeed_mps',
      value: 0,
      info: 'Hook Speed Info: Input Hook Speed value to determine what speed in m/s trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving down - block position',
      unit: '(m)',
      tagname: 'TrippingInBlockPosition_m',
      value: 0,
      info: 'Block Position Info: Input Block Position value where redzone will be deactivated whenever block position is below value.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving down - block weight',
      unit: '(N)',
      tagname: 'TrippingInHookload_N',
      value: 0,
      info: 'Block Weight: Input Block Weight value in newton to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving up - hook speed',
      unit: '(m/s)',
      tagname: 'TrippingOutHookSpeed_mps',
      value: 0,
      info: 'Hook Speed Info: Input Hook Speed value to determine what speed in m/s trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving up - block weight',
      unit: '(N)',
      tagname: 'TrippingOutHookload_N',
      value: 0,
      info: 'Block Weight: Input Block Weight value in newton to trigger redzone',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Circulating - cement pump',
      tagname: 'CirculatingCementPump_N',
      unit: '(N)',
      value: 0,
      info: 'Cement Pump: Input Cement Pump value to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Circulating - block weight',
      unit: '(N)',
      tagname: 'CirculatingHookload_N',
      value: 0,
      info: 'Block Weight: Input Block Weight value in newton to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Circulating flow pump',
      unit: '(m3/s)',
      tagname: 'CirculatingFlowPump_m3ps',
      value: 0,
      info: 'Flow Pump: Input Flow Pump value to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotating torque threshold',
      unit: '(N.m)',
      tagname: 'DrilRotateTorqueThreshold_Nm',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotating RPM threshold',
      unit: '(rpm)',
      tagname: 'DrilRotateRpmThreshold_rpm',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotate flow pump',
      unit: '(m3/s)',
      tagname: 'DrilRotateFlowPump_m3ps',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Driling/Rotating standpipe pressure threshold',
      unit: '(Pa)',
      tagname: 'DrilRotateStandpipePressureThreshold_Pa',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotate bit on bottom threshold',
      unit: '(m)',
      tagname: 'DrilRotateBitOnBottomThreshold_m',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
  ];

  public static bu2: ZoneSettingOptions[] = [
    {
      label: 'Block velocity upper bound',
      unit: '(m/s)',
      tagname: 'BlockVelocityUpperBound_mps',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block velocity lower bound',
      unit: '(m/s)',
      tagname: 'BlockVelocityLowerBound_mps',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block position upper bound',
      unit: '(m)',
      tagname: 'BlockPositionUpperBound_m',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block position lower bound',
      unit: '(m)',
      info: '',
      tagname: 'BlockPositionLowerBound_m',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'RPM threshold',
      unit: '(rpm)',
      tagname: 'RPMThreshold_rpm',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'Standpipe pressure threshold',
      unit: '(Pa)',
      tagname: 'StandpipePressureThreshold_Pa',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
  ];

  public static bu3: ZoneSettingOptions[] = [
    {
      label: 'Block position lower bound',
      unit: '(m)',
      info: '',
      tagname: 'BlockPositionLowerBound_m',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block position upper bound',
      unit: '(m)',
      tagname: 'BlockPositionUpperBound_m',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block velocity threshold',
      unit: '(m/s)',
      tagname: 'BlockVelocityUpperBound_mps',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Hookload threshold',
      unit: '(N)',
      tagname: 'HookloadUpperBound_N',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'RPM threshold',
      unit: '(rpm)',
      tagname: 'RPMThreshold_rpm',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'Standpipe pressure threshold',
      unit: '(Pa)',
      tagname: 'StandpipePressureThreshold_Pa',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
  ];
  public static successMessage = 'Saved Succesfully!';
  public static errorMessage = 'Error occured while saving event review.';
  public static zone_errorMessage = 'Error occured while saving zone settings.';
  public static zoneSuccessMessage = 'Zone settings saved succesfully!';

  public static BU_logic_1: any = {
    TrippingInHookSpeed_mps: 0,
    TrippingInBlockPosition_m: 0,
    TrippingInHookload_N: 0,
    TrippingOutHookSpeed_mps: 0,
    TrippingOutHookload_N: 0,
    CirculatingCementPump_N: 0,
    CirculatingHookload_N: 0,
    CirculatingFlowPump_m3ps: 0,
    DrilRotateTorqueThreshold_Nm: 0,
    DrilRotateRpmThreshold_rpm: 0,
    DrilRotateFlowPump_m3ps: 0,
    DrilRotateStandpipePressureThreshold_Pa: 0,
    DrilRotateBitOnBottomThreshold_m: 0,
  };

  public static BU_logic_2: any = {
    BlockVelocityUpperBound_mps: 0,
    BlockVelocityLowerBound_mps: 0,
    BlockPositionUpperBound_m: 0,
    BlockPositionLowerBound_m: 0,
    RPMThreshold_rpm: 0,
    StandpipePressureThreshold_Pa: 0,
  };

  public static BU_logic_3: any = {
    BlockVelocityUpperBound_mps: 0,
    HookloadUpperBound_N: 0,
    RPMThreshold_rpm: 0,
    StandpipePressureThreshold_Pa: 0,
    BlockPositionUpperBound_m: 0,
    BlockPositionLowerBound_m: 0,
  };
}
