import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SlbModalModule } from '@slb-dls/angular-material/modal';
import { PopUpService } from './services/pop-up.service';
import { SlbButtonModule } from '@slb-dls/angular-material/button';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CarouselComponent } from './carousel/carousel.component';
import { ImageStyleDirective } from '../image-style.directive';

@NgModule({
  declarations: [ConfirmDialogComponent, DoughnutChartComponent, SpinnerComponent, CarouselComponent, ImageStyleDirective],
  imports: [CommonModule, MatDialogModule, SlbModalModule, SlbButtonModule, MatIconModule, MatProgressSpinnerModule],
  exports: [ConfirmDialogComponent, DoughnutChartComponent, SpinnerComponent, CarouselComponent, ImageStyleDirective],
  providers: [PopUpService],
})
export class SharedModule {}
