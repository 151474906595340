import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CameraComponent } from './camera/camera.component';
import { CameraProfileDashboardComponent } from './camera-profile/camera-profile-dashboard/camera-profile-dashboard.component';
import { LoginComponent } from '@agora/agora-ui-library';
import { AuthGuard } from './authentication/auth.guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ZoneDefinitionComponent } from './zone-manager/zone-definition/zone-definition.component';
import { UnsubscribedComponent } from './unsubscribe/unsubscribed.component';
import { UserSettingComponent } from './global-settings/user-setting/user-setting.component';
import { UnitsComponent } from './global-settings/units/units.component';
import { GlobalViewComponent } from './global-view/global-view.component';
import { NotificationComponent } from './global-settings/notification/notification.component';
import { NotificationPageComponent } from './notification-page/notification-page.component';
import { AdminComponent } from './global-settings/admin/admin.component';

const routes: Routes = [
  {
    path: 'login/logincallback',
    component: LoginComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: DashboardComponent,
    data: {
      title: 'Dashboard',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'camera',
    component: CameraComponent,
    data: {
      title: 'Cameras',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'analytics',
    component: GlobalViewComponent,
    data: {
      title: 'Analytics',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'zone',
    loadChildren: () => import('./zone-manager/zone-manager.module').then(m => m.ZoneManagerModule),
    data: {
      title: 'Zone Manager',
      showHeader: true,
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'home/:cameraName/:cameraStatus/:gateway/:controllerId/:rigName',
    component: CameraProfileDashboardComponent,
    data: {
      title: 'Camera',
      showBackButton: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'zoneDefinition/:cameraName/:cameraStatus/:gateway/:controllerId/:rigName',
    component: ZoneDefinitionComponent,
    data: {
      title: 'Zone Definition',
      showBackButton: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      title: '',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'home/unsubscribed/:useremail/:orgid',
    component: UnsubscribedComponent,
    data: {
      title: '',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    component: NotificationPageComponent,
    data: {
      title: 'Notification',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'userSettings',
    component: UserSettingComponent,
    data: {
      title: 'User settings',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
    children: [
      { path: 'unit', component: UnitsComponent },
      {
        path: '',
        redirectTo: 'unit',
        pathMatch: 'full',
      },
      {
        path: 'admin',
        component: AdminComponent,
      },
      {
        path: 'notification',
        component: NotificationComponent,
      },
    ],
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
