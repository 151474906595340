import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

interface ImgParams {
  width?: number;
  height?: number;
  top: number;
  left: number;
  pWidth?: number;
  pHeight?: number;
}

export enum Fits {
  ScaleDown = 'scale-down',
  Cover = 'cover',
  Natural = 'natural',
  Default = 'fit',
}

@Directive({
  selector: '[appImageStyle]',
})
export class ImageStyleDirective implements OnInit {
  @Input() initialFit?: Fits;
  @HostBinding('style.aspect-ratio') aspectratio: number;
  @HostBinding('style.width.px') width: number;
  @HostBinding('style.height.px') height: number;

  public img: HTMLImageElement;
  public container: HTMLElement | null;
  public ratio = 1;
  public initialParams: ImgParams = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  };
  public currentParams: ImgParams = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    pWidth: 0,
    pHeight: 0,
  };

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.backgroundColor = 'white';
  }

  ngOnInit(): void {
    this.img = this.el.nativeElement;
    this.container = this.img?.parentElement;
    this.initialFit = this.initialFit || Fits.Natural;
    this.img.addEventListener('load', _e => {
      this.setInitialParams();
    });
  }

  public setInitialParams(): void {
    this.ratio = this.img.naturalWidth / this.img.naturalHeight;
    this.currentParams.pHeight = this.container?.offsetHeight;
    this.currentParams.pWidth = this.container?.offsetWidth;

    switch (this.initialFit) {
      case 'natural':
        this.initialParams.width = Math.min(this.currentParams.pWidth ?? 0, this.img.naturalWidth);
        this.initialParams.height = this.initialParams.width / (this.img.naturalWidth / this.img.naturalHeight);
        if (this.currentParams.pHeight && this.initialParams.height > this.currentParams?.pHeight) {
          this.initialParams.height = Math.min(this.currentParams.pHeight ?? 0, this.img.naturalHeight);
          this.initialParams.width = this.initialParams.height * (this.img.naturalWidth / this.img.naturalHeight);
        }
        break;

      case 'fit':
        this.initialParams.width = this.currentParams.pWidth;
        this.initialParams.height = (this.initialParams.width ?? 0) / (this.img.naturalWidth / this.img.naturalHeight);
        if (this.currentParams.pHeight && this.initialParams.height > this.currentParams.pHeight) {
          this.initialParams.height = this.currentParams.pHeight;
          this.initialParams.width = this.initialParams.height * (this.img.naturalWidth / this.img.naturalHeight);
        }
        break;
    }
    this.setImage(this.initialParams);
  }

  public setImage(imgParams: ImgParams): void {
    this.width = imgParams.width || this.width;
    this.height = imgParams.height || this.height;
    this.aspectratio = this.ratio;
  }
}
