<div class="zone-setting-container" *ngIf="previewError === '' && !showLoader; else loader">
  <p class="disable-msg error" *ngIf="status === 'Error' && !inactiveCamera && !inactiveGateway">Configuration deployment failed.</p>
  <div class="zone-setting-red-zone" *ngIf="workflow === 'redzone'">
    <slb-radio-button-group
      (valueChanged)="zoneService.isValueChange = true"
      [disabled]="inactiveGateway || inactiveCamera"
      [isVertical]="false"
      label="Setting Properties"
      [(ngModel)]="currentRedZone"
      [values]="redZoneTypes"></slb-radio-button-group>
  </div>
  <div class="zone-settings-configuration" *ngIf="workflow === 'redzone'">
    <div class="zone-setting-grid-row" *ngFor="let zone of zoneSettings; let i = index">
      <mat-form-field slbFormField class="workflow-zone-settings">
        <mat-label class="setting-text" [matTooltip]="zone.label + ' ' + zone.unit"> {{ zone.label }}{{ zone.unit }} </mat-label>
        <input
          matInput
          name="zoneValueField"
          type="number"
          pattern="^-?\d*[.,]?\d{0,7}$"
          required
          [(ngModel)]="zone.zoneUpdatedValue"
          #zoneValueField="ngModel"
          (ngModelChange)="checkZoneSettingValid($event, i)"
          [disabled]="
            !(
              currentRedZone === 2 ||
              (currentRedZone === 1 && (zone.tagname === 'people_count' || zone.tagname === 'TimeInsideZone_sec'))
            ) ||
            inactiveGateway ||
            inactiveCamera
          " />
        <mat-hint *ngIf="zoneValueField.errors && (zoneValueField.dirty || zoneValueField.touched)">
          <span class="error"> Invalid input </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="ppe-title-container" *ngIf="!showLoader; else loader">
    <p class="ppe-title-text" *ngIf="workflow === 'PPE' && previewError === '' && zoneConfiguration?.zoneConfigFileUrl !== ''">
      <strong>Enable feature for this camera</strong>
    </p>
  </div>
  <div class="ppe-setting-configuration" *ngIf="workflow === 'ppe' && previewError === '' && zoneConfiguration?.zoneConfigFileUrl !== ''">
    <div class="ppe-setting-grid-row" *ngFor="let event of ppeEventsOptions">
      <div class="ppe-configuration-box">
        <mat-checkbox [(ngModel)]="event.isChecked" (ngModelChange)="ppeSettingChange()" class="ppe-checkbox">
          <p>
            <span><img src="{{ event.img }}" class="ppe-checkbox-img" /></span><span class="ppe-label">{{ event.label }}</span>
          </p>
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>
<ng-template #loader>
  <div class="spinner">
    <mat-spinner *ngIf="showLoader" [diameter]="40"></mat-spinner>
  </div>
</ng-template>
