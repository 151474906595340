import { TimeZone } from '../models/dashabordAlertData';

export const TIMEZONE: TimeZone[] = [
  {
    label: 'International Date Line West',
    value: '(UTC-12:00)',
    area: ['Etc/GMT+12'],
    timezone: 'UTC -12:00',
    dtTimezone: 'UTC -12:00',
  },
  {
    label: 'Coordinated Universal Time-11',
    value: '(UTC-11:00)',
    area: ['Etc/GMT+11'],
    timezone: 'UTC -11:00',
    dtTimezone: 'UTC -11:00',
  },
  {
    label: 'Aleutian Islands',
    value: '(UTC-10:00)',
    area: ['America/Adak'],
    timezone: 'UTC -10:00',
    dtTimezone: 'UTC -9:00',
  },
  {
    label: 'Hawaii',
    value: '(UTC-10:00)',
    area: ['Pacific/Honolulu'],
    timezone: 'UTC -10:00',
    dtTimezone: 'UTC -10:00',
  },
  {
    label: 'Marquesas Islands',
    value: '(UTC-09:30)',
    area: ['Pacific/Marquesas'],
    timezone: 'UTC -09:30',
    dtTimezone: 'UTC -09:30',
  },
  {
    label: 'Coordinated Universal Time-09',
    value: '(UTC-09:00)',
    area: ['Etc/GMT+9'],
    timezone: 'UTC -09:00',
    dtTimezone: 'UTC -09:00',
  },
  {
    label: 'Alaska',
    value: '(UTC-09:00)',
    area: ['America/Anchorage'],
    timezone: 'UTC -09:00',
    dtTimezone: 'UTC -08:00',
  },
  {
    label: 'Coordinated Universal Time-08',
    value: '(UTC-08:00)',
    area: ['Etc/GMT+8'],
    timezone: 'UTC -08:00',
    dtTimezone: 'UTC -08:00',
  },
  {
    label: 'Baja California',
    value: '(UTC-08:00)',
    area: ['America/Tijuana'],
    timezone: 'UTC -08:00',
    dtTimezone: 'UTC -07:00',
  },
  {
    label: 'Pacific Time (US & Canada)',
    value: '(UTC-08:00)',
    area: ['America/Los_Angeles'],
    timezone: 'UTC -08:00',
    dtTimezone: 'UTC -07:00',
  },
  {
    label: 'Arizona',
    value: '(UTC-07:00)',
    area: ['America/Phoenix'],
    timezone: 'UTC -07:00',
    dtTimezone: 'UTC -07:00',
  },
  {
    label: 'La Paz, Mazatlan',
    value: '(UTC-07:00)',
    area: ['America/Mazatlan'],
    timezone: 'UTC -07:00',
    dtTimezone: 'UTC -07:00',
  },
  {
    label: 'Mountain Time (US & Canada)',
    value: '(UTC-07:00)',
    area: ['America/Denver'],
    timezone: 'UTC -07:00',
    dtTimezone: 'UTC -06:00',
  },
  {
    label: 'Yukon',
    value: '(UTC-07:00)',
    area: ['America/Whitehorse'],
    timezone: 'UTC -07:00',
    dtTimezone: 'UTC -07:00',
  },
  {
    label: 'Central America',
    value: '(UTC-06:00)',
    area: ['America/Guatemala'],
    timezone: 'UTC -06:00',
    dtTimezone: 'UTC -06:00',
  },
  {
    label: 'Central Time (US & Canada)',
    value: '(UTC-06:00)',
    area: ['America/Chicago'],
    timezone: 'UTC -06:00',
    dtTimezone: 'UTC -05:00',
  },
  {
    label: 'Easter Island',
    value: '(UTC-06:00)',
    area: ['Pacific/Easter'],
    timezone: 'UTC -06:00',
    dtTimezone: 'UTC -05:00',
  },
  {
    label: 'Guadalajara, Mexico City, Monterrey',
    value: '(UTC-06:00)',
    area: ['America/Mexico_City'],
    timezone: 'UTC -06:00',
    dtTimezone: 'UTC -06:00',
  },
  {
    label: 'Saskatchewan',
    value: '(UTC-06:00)',
    area: ['America/Regina'],
    timezone: 'UTC -06:00',
    dtTimezone: 'UTC -06:00',
  },
  {
    label: 'Bogota, Lima, Quito, Rio Branco',
    value: '(UTC-05:00)',
    area: ['America/Bogota'],
    timezone: 'UTC -05:00',
    dtTimezone: 'UTC -05:00',
  },
  {
    label: 'Chetumal',
    value: '(UTC-05:00)',
    area: ['America/Cancun'],
    timezone: 'UTC -05:00',
    dtTimezone: 'UTC -05:00',
  },
  {
    label: 'Eastern Time (US & Canada)',
    value: '(UTC-05:00)',
    area: ['America/New_York'],
    timezone: 'UTC -05:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Haiti',
    value: '(UTC-05:00)',
    area: ['America/Port-au-Prince'],
    timezone: 'UTC -05:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Havana',
    value: '(UTC-05:00)',
    area: ['America/Havana'],
    timezone: 'UTC -05:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Indiana (East)',
    value: '(UTC-05:00)',
    area: ['America/Indianapolis'],
    timezone: 'UTC -05:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Turks and Caicos',
    value: '(UTC-05:00)',
    area: ['America/Grand_Turk'],
    timezone: 'UTC -05:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Asuncion',
    value: '(UTC-04:00)',
    area: ['America/Asuncion'],
    timezone: 'UTC -04:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Atlantic Time (Canada)',
    value: '(UTC-04:00)',
    area: ['America/Halifax'],
    timezone: 'UTC -04:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Caracas',
    value: '(UTC-04:00)',
    area: ['America/Caracas'],
    timezone: 'UTC -04:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Cuiaba',
    value: '(UTC-04:00)',
    area: ['America/Cuiaba'],
    timezone: 'UTC -04:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Georgetown, La Paz, Manaus, San Juan',
    value: '(UTC-04:00)',
    area: ['America/La_Paz'],
    timezone: 'UTC -04:00',
    dtTimezone: 'UTC -04:00',
  },
  {
    label: 'Santiago',
    value: '(UTC-04:00)',
    area: ['America/Santiago'],
    timezone: 'UTC -04:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Newfoundland',
    value: '(UTC-03:30)',
    area: ['America/St_Johns'],
    timezone: 'UTC -03:30',
    dtTimezone: 'UTC -02:30',
  },
  {
    label: 'Araguaina',
    value: '(UTC-03:00)',
    area: ['America/Araguaina'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Brasilia',
    value: '(UTC-03:00)',
    area: ['America/Sao_Paulo'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Cayenne, Fortaleza',
    value: '(UTC-03:00)',
    area: ['America/Cayenne'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'City of Buenos Aires',
    value: '(UTC-03:00)',
    area: ['America/Buenos_Aires'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Greenland',
    value: '(UTC-03:00)',
    area: ['America/Godthab'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Montevideo',
    value: '(UTC-03:00)',
    area: ['America/Montevideo'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Punta Arenas',
    value: '(UTC-03:00)',
    area: ['America/Punta_Arenas'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: '(UTC-03:00)',
    area: ['America/Miquelon'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -02:00',
  },
  {
    label: 'Salvador',
    value: '(UTC-03:00)',
    area: ['America/Bahia'],
    timezone: 'UTC -03:00',
    dtTimezone: 'UTC -03:00',
  },
  {
    label: 'Coordinated Universal Time-02',
    value: '(UTC-02:00)',
    area: ['Etc/GMT+2'],
    timezone: 'UTC -02:00',
    dtTimezone: 'UTC -02:00',
  },
  {
    label: 'Azores',
    value: '(UTC-01:00)',
    area: ['Atlantic/Azores'],
    timezone: 'UTC -01:00',
    dtTimezone: 'UTC -00:00',
  },
  {
    label: 'Cabo Verde Is.',
    value: '(UTC-01:00)',
    area: ['Atlantic/Cape_Verde'],
    timezone: 'UTC -01:00',
    dtTimezone: 'UTC -01:00',
  },
  {
    label: 'Coordinated Universal Time',
    value: '(UTC+00:00)',
    area: ['UTC'],
    timezone: 'UTC +00:00',
    dtTimezone: 'UTC +00:00',
  },
  {
    label: 'Dublin, Edinburgh, Lisbon, London',
    value: '(UTC+00:00)',
    area: ['Europe/London'],
    timezone: 'UTC +00:00',
    dtTimezone: 'UTC +01:00',
  },
  {
    label: 'Monrovia, Reykjavik',
    value: '(UTC+00:00)',
    area: ['Atlantic/Reykjavik'],
    timezone: 'UTC +00:00',
    dtTimezone: 'UTC +01:00',
  },
  {
    label: 'Sao Tome',
    value: '(UTC+00:00)',
    area: ['Africa/Sao_Tome'],
    timezone: 'UTC +00:00',
    dtTimezone: 'UTC +00:00',
  },
  {
    label: 'Casablanca',
    value: '(UTC+01:00)',
    area: ['Africa/Casablanca'],
    timezone: 'UTC +01:00',
    dtTimezone: 'UTC +00:00',
  },
  {
    label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: '(UTC+01:00)',
    area: ['Europe/Berlin'],
    timezone: 'UTC +01:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: '(UTC+01:00)',
    area: ['Europe/Budapest'],
    timezone: 'UTC +01:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Brussels, Copenhagen, Madrid, Paris',
    value: '(UTC+01:00)',
    area: ['Europe/Paris'],
    timezone: 'UTC +01:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Sarajevo, Skopje, Warsaw, Zagreb',
    value: '(UTC+01:00)',
    area: ['Europe/Warsaw'],
    timezone: 'UTC +01:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'West Central Africa',
    value: '(UTC+01:00)',
    area: ['Africa/Lagos'],
    timezone: 'UTC +01:00',
    dtTimezone: 'UTC +01:00',
  },
  {
    label: 'Athens, Bucharest',
    value: '(UTC+02:00)',
    area: ['Europe/Bucharest'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Beirut',
    value: '(UTC+02:00)',
    area: ['Asia/Beirut'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Cairo',
    value: '(UTC+02:00)',
    area: ['Africa/Cairo'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Chisinau',
    value: '(UTC+02:00)',
    area: ['Europe/Chisinau'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Damascus',
    value: '(UTC+02:00)',
    area: ['Asia/Damascus'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Gaza, Hebron',
    value: '(UTC+02:00)',
    area: ['Asia/Hebron'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Harare, Pretoria',
    value: '(UTC+02:00)',
    area: ['Africa/Johannesburg'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: '(UTC+02:00)',
    area: ['Europe/Kiev'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Jerusalem',
    value: '(UTC+02:00)',
    area: ['Asia/Jerusalem'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Juba',
    value: '(UTC+02:00)',
    area: ['Africa/Juba'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Kaliningrad',
    value: '(UTC+02:00)',
    area: ['Europe/Kaliningrad'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Khartoum',
    value: '(UTC+02:00)',
    area: ['Africa/Khartoum'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Tripoli',
    value: '(UTC+02:00)',
    area: ['Africa/Tripoli'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Windhoek',
    value: '(UTC+02:00)',
    area: ['Africa/Windhoek'],
    timezone: 'UTC +02:00',
    dtTimezone: 'UTC +02:00',
  },
  {
    label: 'Amman',
    value: '(UTC+03:00)',
    area: ['Asia/Amman'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Baghdad',
    value: '(UTC+03:00)',
    area: ['Asia/Baghdad'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Istanbul',
    value: '(UTC+03:00)',
    area: ['Europe/Istanbul'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Kuwait, Riyadh',
    value: '(UTC+03:00)',
    area: ['Asia/Riyadh'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Minsk',
    value: '(UTC+03:00)',
    area: ['Europe/Minsk'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Moscow, St. Petersburg',
    value: '(UTC+03:00)',
    area: ['Europe/Moscow'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Nairobi',
    value: '(UTC+03:00)',
    area: ['Africa/Nairobi'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Volgograd',
    value: '(UTC+03:00)',
    area: ['Europe/Volgograd'],
    timezone: 'UTC +03:00',
    dtTimezone: 'UTC +03:00',
  },
  {
    label: 'Tehran',
    value: '(UTC+03:30)',
    area: ['Asia/Tehran'],
    timezone: 'UTC +03:30',
    dtTimezone: 'UTC +03:30',
  },
  {
    label: 'Abu Dhabi, Muscat',
    value: '(UTC+04:00)',
    area: ['Asia/Dubai'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Astrakhan, Ulyanovsk',
    value: '(UTC+04:00)',
    area: ['Europe/Astrakhan'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Baku',
    value: '(UTC+04:00)',
    area: ['Asia/Baku'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Izhevsk, Samara',
    value: '(UTC+04:00)',
    area: ['Europe/Samara'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Port Louis',
    value: '(UTC+04:00)',
    area: ['Indian/Mauritius'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Saratov',
    value: '(UTC+04:00)',
    area: ['Europe/Saratov'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Tbilisi',
    value: '(UTC+04:00)',
    area: ['Asia/Tbilisi'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Yerevan',
    value: '(UTC+04:00)',
    area: ['Asia/Yerevan'],
    timezone: 'UTC +04:00',
    dtTimezone: 'UTC +04:00',
  },
  {
    label: 'Kabul',
    value: '(UTC+04:30)',
    area: ['Asia/Kabul'],
    timezone: 'UTC +04:30',
    dtTimezone: 'UTC +04:30',
  },
  {
    label: 'Ashgabat, Tashkent',
    value: '(UTC+05:00)',
    area: ['Asia/Tashkent'],
    timezone: 'UTC +05:00',
    dtTimezone: 'UTC +05:00',
  },
  {
    label: 'Ekaterinburg',
    value: '(UTC+05:00)',
    area: ['Asia/Yekaterinburg'],
    timezone: 'UTC +05:00',
    dtTimezone: 'UTC +05:00',
  },
  {
    label: 'Islamabad, Karachi',
    value: '(UTC+05:00)',
    area: ['Asia/Karachi'],
    timezone: 'UTC +05:00',
    dtTimezone: 'UTC +05:00',
  },
  {
    label: 'Qyzylorda',
    value: '(UTC+05:00)',
    area: ['Asia/Qyzylorda'],
    timezone: 'UTC +05:00',
    dtTimezone: 'UTC +05:00',
  },
  {
    label: 'Chennai, Kolkata, Mumbai, New Delhi',
    value: '(UTC+05:30)',
    area: ['Asia/Calcutta'],
    timezone: 'UTC +05:30',
    dtTimezone: 'UTC +05:30',
  },
  {
    label: 'Sri Jayawardenepura',
    value: '(UTC+05:30)',
    area: ['Asia/Colombo'],
    timezone: 'UTC +05:30',
    dtTimezone: 'UTC +05:30',
  },
  {
    label: 'Kathmandu',
    value: '(UTC+05:45)',
    area: ['Asia/Katmandu'],
    timezone: 'UTC +05:45',
    dtTimezone: 'UTC +05:45',
  },
  {
    label: 'Astana',
    value: '(UTC+06:00)',
    area: ['Asia/Almaty'],
    timezone: 'UTC +06:00',
    dtTimezone: 'UTC +06:00',
  },
  {
    label: 'Dhaka',
    value: '(UTC+06:00)',
    area: ['Asia/Dhaka'],
    timezone: 'UTC +06:00',
    dtTimezone: 'UTC +06:00',
  },
  {
    label: 'Omsk',
    value: '(UTC+06:00)',
    area: ['Asia/Omsk'],
    timezone: 'UTC +06:00',
    dtTimezone: 'UTC +06:00',
  },
  {
    label: 'Yangon (Rangoon)',
    value: '(UTC+06:30)',
    area: ['Asia/Rangoon'],
    timezone: 'UTC +06:30',
    dtTimezone: 'UTC +06:30',
  },
  {
    label: 'Bangkok, Hanoi, Jakarta',
    value: '(UTC+07:00)',
    area: ['Asia/Bangkok'],
    timezone: 'UTC +07:00',
    dtTimezone: 'UTC +07:00',
  },
  {
    label: 'Barnaul, Gorno-Altaysk',
    value: '(UTC+07:00)',
    area: ['Asia/Barnaul'],
    timezone: 'UTC +07:00',
    dtTimezone: 'UTC +07:00',
  },
  {
    label: 'Hovd',
    value: '(UTC+07:00)',
    area: ['Asia/Hovd'],
    timezone: 'UTC +07:00',
    dtTimezone: 'UTC +07:00',
  },
  {
    label: 'Krasnoyarsk',
    value: '(UTC+07:00)',
    area: ['Asia/Krasnoyarsk'],
    timezone: 'UTC +07:00',
    dtTimezone: 'UTC +07:00',
  },
  {
    label: 'Novosibirsk',
    value: '(UTC+07:00)',
    area: ['Asia/Novosibirsk'],
    timezone: 'UTC +07:00',
    dtTimezone: 'UTC +07:00',
  },
  {
    label: 'Tomsk',
    value: '(UTC+07:00)',
    area: ['Asia/Tomsk'],
    timezone: 'UTC +07:00',
    dtTimezone: 'UTC +07:00',
  },
  {
    label: 'Beijing, Chongqing, Hong Kong, Urumqi',
    value: '(UTC+08:00)',
    area: ['Asia/Shanghai'],
    timezone: 'UTC +08:00',
    dtTimezone: 'UTC +08:00',
  },
  {
    label: 'Irkutsk',
    value: '(UTC+08:00)',
    area: ['Asia/Irkutsk'],
    timezone: 'UTC +08:00',
    dtTimezone: 'UTC +08:00',
  },
  {
    label: 'Kuala Lumpur, Singapore',
    value: '(UTC+08:00)',
    area: ['Asia/Singapore', 'Asia/Kuala_Lumpur'],
    timezone: 'UTC +08:00',
    dtTimezone: 'UTC +08:00',
  },
  {
    label: 'Perth',
    value: '(UTC+08:00)',
    area: ['Australia/Perth'],
    timezone: 'UTC +08:00',
    dtTimezone: 'UTC +08:00',
  },
  {
    label: 'Taipei',
    value: '(UTC+08:00)',
    area: ['Asia/Taipei'],
    timezone: 'UTC +08:00',
    dtTimezone: 'UTC +08:00',
  },
  {
    label: 'Ulaanbaatar',
    value: '(UTC+08:00)',
    area: ['Asia/Ulaanbaatar'],
    timezone: 'UTC +08:00',
    dtTimezone: 'UTC +08:00',
  },
  {
    label: 'Eucla',
    value: '(UTC+08:45)',
    area: ['Australia/Eucla'],
    timezone: 'UTC +08:45',
    dtTimezone: 'UTC +08:45',
  },
  {
    label: 'Chita',
    value: '(UTC+09:00)',
    area: ['Asia/Chita'],
    timezone: 'UTC +09:00',
    dtTimezone: 'UTC +09:00',
  },
  {
    label: 'Osaka, Sapporo, Tokyo',
    value: '(UTC+09:00)',
    area: ['Asia/Tokyo'],
    timezone: 'UTC +09:00',
    dtTimezone: 'UTC +09:00',
  },
  {
    label: 'Pyongyang',
    value: '(UTC+09:00)',
    area: ['Asia/Pyongyang'],
    timezone: 'UTC +09:00',
    dtTimezone: 'UTC +09:00',
  },
  {
    label: 'Seoul',
    value: '(UTC+09:00)',
    area: ['Asia/Seoul'],
    timezone: 'UTC +09:00',
    dtTimezone: 'UTC +09:00',
  },
  {
    label: 'Yakutsk',
    value: '(UTC+09:00)',
    area: ['Asia/Yakutsk'],
    timezone: 'UTC +09:00',
    dtTimezone: 'UTC +09:00',
  },
  {
    label: 'Adelaide',
    value: '(UTC+09:30)',
    area: ['Australia/Adelaide'],
    timezone: 'UTC +09:30',
    dtTimezone: 'UTC +10:30',
  },
  {
    label: 'Darwin',
    value: '(UTC+09:30)',
    area: ['Australia/Darwin'],
    timezone: 'UTC +09:30',
    dtTimezone: 'UTC +09:30',
  },
  {
    label: 'Brisbane',
    value: '(UTC+10:00)',
    area: ['Australia/Brisbane'],
    timezone: 'UTC +10:00',
    dtTimezone: 'UTC +10:00',
  },
  {
    label: 'Canberra, Melbourne, Sydney',
    value: '(UTC+10:00)',
    area: ['Australia/Sydney'],
    timezone: 'UTC +10:00',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Guam, Port Moresby',
    value: '(UTC+10:00)',
    area: ['Pacific/Port_Moresby'],
    timezone: 'UTC +10:00',
    dtTimezone: 'UTC +10:00',
  },
  {
    label: 'Hobart',
    value: '(UTC+10:00)',
    area: ['Australia/Hobart'],
    timezone: 'UTC +10:00',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Vladivostok',
    value: '(UTC+10:00)',
    area: ['Asia/Vladivostok'],
    timezone: 'UTC +10:00',
    dtTimezone: 'UTC +10:00',
  },
  {
    label: 'Lord Howe Island',
    value: '(UTC+10:30)',
    area: ['Australia/Lord_Howe'],
    timezone: 'UTC +10:30',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Bougainville Island',
    value: '(UTC+11:00)',
    area: ['Pacific/Bougainville'],
    timezone: 'UTC +11:00',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Chokurdakh',
    value: '(UTC+11:00)',
    area: ['Asia/Srednekolymsk'],
    timezone: 'UTC +11:00',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Magadan',
    value: '(UTC+11:00)',
    area: ['Asia/Magadan'],
    timezone: 'UTC +11:00',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Norfolk Island',
    value: '(UTC+11:00)',
    area: ['Pacific/Norfolk'],
    timezone: 'UTC +11:00',
    dtTimezone: 'UTC +12:00',
  },
  {
    label: 'Sakhalin',
    value: '(UTC+11:00)',
    area: ['Asia/Sakhalin'],
    timezone: 'UTC +11:00',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Solomon Is., New Caledonia',
    value: '(UTC+11:00)',
    area: ['Pacific/Efate'],
    timezone: 'UTC +11:00',
    dtTimezone: 'UTC +11:00',
  },
  {
    label: 'Anadyr, Petropavlovsk-Kamchatsky',
    value: '(UTC+12:00)',
    area: ['Asia/Kamchatka'],
    timezone: 'UTC +12:00',
    dtTimezone: 'UTC +12:00',
  },
  {
    label: 'Auckland, Wellington',
    value: '(UTC+12:00)',
    area: ['Pacific/Auckland'],
    timezone: 'UTC +12:00',
    dtTimezone: 'UTC +13:00',
  },
  {
    label: 'Coordinated Universal Time+12',
    value: '(UTC+12:00)',
    area: ['Etc/GMT-12'],
    timezone: 'UTC +12:00',
    dtTimezone: 'UTC +12:00',
  },
  {
    label: 'Fiji',
    value: '(UTC+12:00)',
    area: ['Pacific/Fiji'],
    timezone: 'UTC +12:00',
    dtTimezone: 'UTC +12:00',
  },
  {
    label: 'Chatham Islands',
    value: '(UTC+12:45)',
    area: ['Pacific/Chatham'],
    timezone: 'UTC +12:45',
    dtTimezone: 'UTC +13:45',
  },
  {
    label: 'Coordinated Universal Time+13',
    value: '(UTC+13:00)',
    area: ['Etc/GMT-13'],
    timezone: 'UTC +13:00',
    dtTimezone: 'UTC +13:00',
  },
  {
    label: 'Nuku alofa',
    value: '(UTC + 13:00)',
    area: ['Pacific/Tongatapu'],
    timezone: 'UTC  + 13:00',
    dtTimezone: 'UTC  + 13:00',
  },
  {
    label: 'Samoa',
    value: '(UTC+13:00)',
    area: ['Pacific/Apia'],
    timezone: 'UTC +13:00',
    dtTimezone: 'UTC +13:00',
  },
  {
    label: 'Kiritimati Island',
    value: '(UTC+14:00)',
    area: ['Pacific/Kiritimati'],
    timezone: 'UTC +14:00',
    dtTimezone: 'UTC +14:00',
  },
];
