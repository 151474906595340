<div class="zone-defination-container">
  <div class="image-message-container">
    <div class="message-container" *ngIf="previewError === '' && zoneConfiguration?.zoneConfigFileUrl !== '' && !zoneConfigLoader">
      <div
        class="zone-description"
        [ngClass]="{ 'no-zone-configuration': noZoneConfiguration, 'has-zone-configuration': !noZoneConfiguration }">
        <p class="description-text">To create a zone, click on the desired area of the image and name the zone before saving.</p>
      </div>
      <div class="zone-metadata">
        <p class="metadata-text" *ngIf="!noZoneConfiguration && zoneConfiguration">
          Zone: <strong>{{ zoneConfiguration.zoneName }}</strong> &nbsp; Last updated at:
          <strong>{{ updatedOn | date : 'MM/dd/yyyy hh:mm:ss a' : currentZone }}</strong> &nbsp; Updated by:
          <strong>{{ updatedBy }}</strong>
        </p>
      </div>
    </div>
    <div class="image-container">
      <div class="image-video-preview" *ngIf="previewError === ''; else imageVideoPreview">
        <app-spinner class="image-loader" *ngIf="imageLoader()"></app-spinner>
        <canvas #canvas [width]="deviceWidth" [height]="deviceHeight" [ngClass]="imageLoader() ? 'hidden' : 'visible'"></canvas>
      </div>
      <ng-template #imageVideoPreview>
        <div class="error-preview" *ngIf="!imageLoader(); else loader">
          <div>
            <p class="error-icon"><img src="assets/icons/icon camera-graphic.png" /></p>
            <p class="preview-title">Definition zone</p>
            <p class="preview-error">{{ previewError }}</p>
          </div>
        </div>
        <ng-template #loader>
          <app-spinner class="image-loader"></app-spinner>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <div class="metadata-container" [ngClass]="buLogic === 1 && workflow !== 'PPE' ? 'metadata-container-bsp' : 'metadata-container'">
    <div class="meta-content" *ngIf="previewError === '' && zoneConfiguration?.zoneConfigFileUrl !== '' && !zoneConfigLoader">
      <div class="meta-content-workflow-remove">
        <mat-form-field id="vi_zone_defination_workflow-field" class="workflow-field">
          <mat-label class="workflow-label">Workflow </mat-label>
          <mat-select
            [(ngModel)]="workflow"
            (selectionChange)="changeWorkflow()"
            [disabled]="(workflowData && workflowData.length === 0) || imageLoader() || inactiveGateway || inactiveCamera">
            <mat-option *ngFor="let workflow of workflowData" value="{{ workflow.id }}">{{ workflow.name | titlecase }} </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-button
          slb-button
          [disabled]="!hasZone || deviceOffline || imageLoader()"
          (click)="removeRedzone()"
          class="remove-zone"
          id="vi_zone_defination_remove_zone_button">
          <mat-icon svgIcon="delete" class="delete-icon"></mat-icon>
          <span class="remove-btn-label"> Remove current zone</span>
        </button>
      </div>
    </div>
    <mat-form-field
      class="zone-name-field"
      slbFormField
      id="vi_zone_defination_zone_name"
      class="workflow-zone-name"
      *ngIf="previewError === '' && zoneConfiguration?.zoneConfigFileUrl !== '' && !zoneConfigLoader">
      <mat-label> Zone name </mat-label>
      <input
        matInput
        pattern="^[a-zA-Z0-9\-\s]*$"
        required
        name="zoneNameField"
        #zoneNameField="ngModel"
        [(ngModel)]="zoneName"
        maxlength="25"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="!hasZone || deviceOffline || imageLoader()"
        (ngModelChange)="checkZoneNameValid()" />
      <mat-hint
        *ngIf="
          (!zoneName || (zoneName && !zoneName.trim().length) || zoneNameField.errors || !isZoneNameValid) &&
          (zoneNameField.dirty || zoneNameField.touched)
        ">
        <span class="error-msg"> Please enter a valid zone name. </span>
      </mat-hint>
    </mat-form-field>
    <hr class="line" *ngIf="previewError === '' && zoneConfiguration?.zoneConfigFileUrl !== '' && !zoneConfigLoader" />
    <div class="zone-settings">
      <app-zone-setting
        [zoneName]="zoneName"
        [workflow]="workflowName"
        [ppeEventsOptions]="ppeEventsOptions"
        [previewError]="previewError"
        [cameraStatus]="cameraStatus"
        [zoneConfiguration]="zoneConfiguration"></app-zone-setting>
    </div>
    <div class="footer" *ngIf="!zoneConfigLoader">
      <button
        id="zone_configuration_dialog_close"
        class="cancel-btn"
        mat-button
        slb-secondary-button
        type="button"
        (click)="navigateToCameraScreen()"
        id="vi_zone_defination_cancel_button">
        Cancel
      </button>
      <button
        id="zone_configuration_dialog_submit"
        class="save-btn"
        mat-button
        slb-button
        type="submit"
        cdkFocusInitial
        (click)="saveZoneConfiguration()"
        [disabled]="disableSave()"
        id="vi_zone_defination_save_button">
        Save
      </button>
    </div>
  </div>
  <ng-template #configLoader>
    <div class="config-loader-preview">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
<slb-toast position="top-right" *ngIf="!showConfirmation"> </slb-toast>
