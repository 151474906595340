import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface calendar {
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
})
export class CustomCalendarComponent implements OnInit {
  public calendarEvent: Event;
  public formattedCalendar: string;
  public formGroup: FormGroup;
  public startDate: Date;
  public selectedDate: Date;
  public isRequired: boolean;
  public endDateError: boolean;
  public currentDateError: boolean;
  public maxValue: Date;
  public todayDate: Date;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public calendarData: calendar,
    public dialogRef: MatDialogRef<CustomCalendarComponent>
  ) {}

  ngOnInit(): void {
    this.maxValue = new Date();
    this.todayDate = new Date();
    this.formGroup = this.formBuilder.group({
      startDate: [this.calendarData.startDate],
      endDate: [this.calendarData.endDate],
    });
  }

  public apply(): void {
    this.endDateError = false;
    this.isRequired = false;
    this.currentDateError = false;
    if (
      this.formGroup.value.startDate === null ||
      this.formGroup.value.endDate === null ||
      this.formGroup.value.startDate === '' ||
      this.formGroup.value.endDate === ''
    ) {
      this.isRequired = true;
    } else if (
      this.formGroup.value.startDate.getTime() > new Date().getTime() ||
      this.formGroup.value.endDate.getTime() > new Date().getTime()
    ) {
      this.currentDateError = true;
    } else if (this.formGroup.value.startDate.getTime() > this.formGroup.value.endDate.getTime()) {
      this.endDateError = true;
    } else {
      this.dialogRef.close({
        type: 'save',
        dates: this.formGroup.value,
      });
    }
  }

  public cancel(): void {
    this.dialogRef.close({
      type: 'cancel',
      dates: '',
    });
  }
}
