import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommentData, CommentDetail, EventDetails, Media, RigDetails, RigValue } from '../shared/models/eventDetails';
import { TimeZoneService } from '../shared/services/time-zone.service';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { Subject, catchError, filter, of, takeUntil, tap } from 'rxjs';
import { CameraProfileService } from '../shared/services/camera-profile.service';
import { DashboardService } from '../shared/services/dashboard.service';
import { UserSettingsService } from '../shared/services/user-settings.service';
import { UnitsData } from '../shared/models/unit';
import { CameraConstants } from '../shared/constants/camera-constant';
import {
  FILE_DOWNLOAD_ERROR,
  EVENTVIEWERROR,
  EVENTFLAGERROR,
  EVENTREVIEWCOMMENTPOSTERROR,
  NOT_REVIEWED,
  NONE,
  EVENTREVIEWCOMMENTPOST,
  NOT_REVIEWED_CAPS,
  EVENT_REVIEW_COMMENT_ERROR,
} from '../shared/constants/camera-profile-constant';
import { RIGDETAILS, RPM, GREEN, RED, BVEL, HOOKLOAD } from '../shared/constants/comment-contant';
import { CameraStatus, GatewayStatus } from '../shared/constants/enum';
import { eventComment, EventView, FlagEventCount, Comments, EventDataView } from '../shared/models/camera-profile';
import { TimeZone } from '../shared/models/dashabordAlertData';
import { LoginService } from '@agora/agora-ui-library';
import { GlobalViewConstant } from '../shared/constants/global-view-constant';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';

@Component({
  selector: 'app-event-popover',
  templateUrl: './event-popover.component.html',
  styleUrls: ['./event-popover.component.scss'],
})
export class EventPopoverComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('scrollMsg', { static: false }) scrollMsg: ElementRef;
  @Input() cameraDetails: EventDetails;
  @Input() hasNext = true;
  @Input() hasPrevious = false;
  @Input() eventIndex = 0;
  @Output() closeSideInfo = new EventEmitter<string>();
  @Output() nextPrevEvent = new EventEmitter<number>();

  public $isImgLoad = new Subject<boolean>();
  public scrollContainer: any;
  public isImage: boolean;
  public isFullscreen = true;
  public currentIndex = 0;
  public currentImageInfo: Media | undefined | null;
  public cameraStatus: CameraStatus;
  public gatewayStatus: GatewayStatus;
  public currentZone = '';
  public media: Media[] = [];
  public currentEventIndex = 0;
  public noImagePreview = false;
  public sessionToken: string;
  public isImgFullScreen: boolean;
  public fullScreenImgSrc: string;
  public commentDetails: CommentData[];
  public redzoneReason: string;
  public fromFullscreen: boolean;
  public reviewComments: eventComment[];
  public userName: string;
  public userEmail: string;
  public flag?: string;
  public comment = '';
  public rigName? = '';
  public getViewCount: EventView;
  public getFlagCount: FlagEventCount;
  public acsUserId = 0;
  public isPostDisabled: boolean;
  public isLoading = true;
  public isExclude = true;
  public viewCount = 0;
  public validCount = 0;
  public invalidCount = 0;
  public excludeCount = 0;
  public selectedFlagButton: string | null | undefined = null;
  public currentFlag?: string;
  public flagTypes: RadioButtonValue[];
  private destroyed = new Subject();
  private unitSelected: UnitsData;
  private rigDetails: RigDetails;
  private rigValue: RigValue;
  private rigData: CommentDetail[];

  constructor(
    private timeZoneService: TimeZoneService,
    private messageService: MessageService,
    private dashboardService: DashboardService,
    private cameraProfileService: CameraProfileService,
    private loginService: LoginService,
    private userSettingService: UserSettingsService
  ) {
    this.userSettingService.unitSelected$
      .pipe(
        tap((value: string) => {
          this.unitSelected = JSON.parse(value);
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    if (changes['cameraDetails'] && changes['cameraDetails'].currentValue) {
      this.currentIndex = 0;
      this.currentEventIndex = this.eventIndex;
      this.media = [];
      this.commentDetails = [];

      if (
        changes.cameraDetails?.previousValue &&
        changes.cameraDetails?.currentValue?.eventId !== changes.cameraDetails?.previousValue?.eventId
      ) {
        this.setReviewComments();
        this.getTotalViewCount();
      }
      if (
        this.cameraDetails?.rigConfiguration &&
        this.cameraDetails?.rigData &&
        this.cameraDetails?.buLogic !== 1 &&
        this.cameraDetails?.buLogic !== 3
      ) {
        this.rigDetails = this.cameraDetails?.rigConfiguration;
        this.rigValue = this.cameraDetails?.rigData;
        this.constructCommentDetails();
      }
      if (this.cameraDetails.media.length > 0) {
        const mediaData = this.cameraDetails.media.filter(m => m.type === 'image' || (m.type === 'video' && m.fileUrl));
        this.media = mediaData;
        this.currentImageInfo = this.cameraDetails.media[this.currentIndex];
        this.noImagePreview = this.cameraDetails?.media.filter(m => m?.fileUrl).length > 0 ? false : true;
        if (this.media.length === 2) {
          const imgData = mediaData.filter(data => data.type === 'image');
          const vidData = mediaData.filter(data => data.type === 'video');
          this.media = JSON.parse(JSON.stringify([...imgData, ...vidData]));
          if (imgData?.[0]?.fileUrl?.includes('mp4')) {
            this.media[1].fileUrl = imgData[0].fileUrl;
          }
          if (vidData.length > 0 && !vidData?.[0]?.fileUrl?.includes('mp4')) {
            this.media[0].fileUrl = vidData[0].fileUrl;
          }
          if (!this.media[0].fileUrl && this.media[0].type === 'image') {
            this.currentIndex = 1;
          }
        }
        this.validateMediaUrl();
      } else {
        this.media.push({ type: 'image', fileUrl: '' });
        this.noImagePreview = this.media?.filter(m => m?.fileUrl && m.fileUrl !== '')?.length > 0 ? false : true;
      }

      if (!this.cameraDetails?.equipmentName) {
        this.cameraDetails.equipmentName = this.cameraDetails.equipmentName;
      }

      if (Number(this.cameraDetails?.cameraStatus) === CameraStatus.Active) {
        this.cameraStatus = CameraStatus.Active;
      } else if (Number(this.cameraDetails?.cameraStatus) === CameraStatus.Inactive) {
        this.cameraStatus = CameraStatus.Inactive;
      } else {
        this.cameraStatus = CameraStatus.Disconnected;
      }

      if (Number(this.cameraDetails?.gatewayStatus) === GatewayStatus.Active) {
        this.gatewayStatus = GatewayStatus.Active;
      } else if (Number(this.cameraDetails?.gatewayStatus) === GatewayStatus.Inactive) {
        this.gatewayStatus = GatewayStatus.Inactive;
      } else {
        this.gatewayStatus = GatewayStatus.Disconnected;
      }
    }
    this.currentFlag =
      !this.cameraDetails.category || this.cameraDetails.category === NONE ? NOT_REVIEWED : this.cameraDetails.category.toLowerCase();
  }

  ngOnInit(): void {
    this.rigName = this.cameraDetails?.rigName?.replace(/\*/g, ' ');

    this.isPostDisabled = true;
    this.isExclude = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
    this.flagTypes = this.isExclude ? GlobalViewConstant.eventFlagTypes : GlobalViewConstant.eventFlagTypesNonAdmin;
    this.setReviewComments();
    this.userName = this.loginService.getUserInfo()?.name ?? '';
    this.userEmail = this.loginService.getUserInfo()?.email ?? '';
    this.flag = this.currentFlag;
    this.getTotalViewCount();
  }

  ngAfterViewInit(): void {
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((_zone: TimeZone) => (this.currentZone = this.timeZoneService.getTimeZone())),
        takeUntil(this.destroyed)
      )
      .subscribe();
    this.scrollContainer = this.scrollMsg.nativeElement;
  }

  public onClose(): void {
    this.isFullscreen = false;
    this.closeSideInfo.emit();
  }

  public onNextPrevClick(type: string): void {
    this.$isImgLoad.next(true);
    if (type === CameraConstants.next) {
      this.currentEventIndex = this.currentEventIndex + 1;
    } else {
      this.currentEventIndex = this.currentEventIndex - 1;
    }
    this.nextPrevEvent.emit(this.currentEventIndex);
    this.currentIndex = 0;
    this.comment = '';
    this.isPostDisabled = true;
  }

  public downloadImages(): void {
    const eventId = this.cameraDetails.eventId;
    if (this.cameraDetails?.gatewayId && this.cameraDetails?.equipmentId && !this.noImagePreview) {
      this.cameraProfileService
        .downloadMediaAsZip(this.cameraDetails.gatewayId, this.cameraDetails.equipmentId, [eventId])
        .pipe(
          tap((data: string) => {
            if (data && data !== '' && this.cameraDetails?.equipmentId) {
              this.dashboardService.downloadZipFile(data, this.cameraDetails?.equipmentId);
            } else {
              this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
            }
          }),
          catchError(() => {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });

            return of<string>('');
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    } else {
      this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
    }
  }

  public openEmail(): void {
    const a = document.createElement('a');
    a.href = 'mailto:';
    a.click();
  }

  public toggleFullscreen(): void {
    this.isFullscreen = !this.isFullscreen;
  }

  public getCurrentIndex(event: { activeSlideIndex: number; totalSlides: number }): void {
    this.currentIndex = event.activeSlideIndex;
  }

  public setCurrentIndex(event: number): void {
    this.currentIndex = event;
  }

  public showImageInFullscreen(imgSrc: string): void {
    this.fullScreenImgSrc = imgSrc + '/?token=' + this.sessionToken;
    this.isImgFullScreen = true;
  }

  public hideImageInFullscreen(): void {
    this.fullScreenImgSrc = '';
    this.isImgFullScreen = false;
  }

  public setReviewComments(): void {
    this.isLoading = true;
    this.cameraProfileService
      .getEventComments(this.cameraDetails.eventId)
      .pipe(
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: EVENT_REVIEW_COMMENT_ERROR,
            closable: true,
            sticky: true,
          });

          return of<Comments>({} as Comments);
        }),
        tap((data: Comments) => {
          if (data.comments.length !== 0) {
            this.reviewComments = data.comments;
          } else {
            this.reviewComments = [];
          }
          this.isLoading = false;
          this.sortRespData(data.comments);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public getTotalViewCount(): void {
    this.cameraProfileService
      .getViewCounts(this.cameraDetails.eventId)
      .pipe(
        tap((resData: EventView) => {
          this.viewCount = !resData ? 0 : resData.totalCount;
          const checkUser = resData?.views.find((user: { userEmail: string }) => user.userEmail === this.userEmail);
          if (!checkUser || !this.viewCount) {
            this.addViewCount();
          }
        }),
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTVIEWERROR });

          return of<string>('');
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public addViewCount(): void {
    this.cameraProfileService
      .createViewCounts(this.cameraDetails.eventId, this.userName, this.userEmail, this.acsUserId)
      .pipe(
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTVIEWERROR });

          return of<string>('');
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public setFlagData(): void {
    this.isPostDisabled = this.flag !== this.currentFlag || this.comment.length !== 0 ? false : true;
  }

  public getInitials(fullName: string): string {
    if (!fullName) {
      return '';
    }
    const nameParts = fullName.trim().split(' ');
    if (nameParts.length < 2) {
      return fullName.charAt(0).toUpperCase();
    }
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';

    return `${firstNameInitial}${lastNameInitial}`;
  }

  public checkComment(): void {
    this.isPostDisabled = this.flag !== this.currentFlag || this.comment.trim().length !== 0 ? false : true;
  }

  public sortRespData(respData: eventComment[]): void {
    respData?.sort((a, b) => {
      if (a?.createdAt && b?.createdAt) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }

      return 0;
    });
  }

  public postComment(): void {
    if (!this.isPostDisabled) {
      if (this.comment.length !== 0) {
        const commentJson = {
          eventId: this.cameraDetails.eventId,
          comment: this.comment,
          username: this.userName,
          userEmail: this.userEmail,
        };
        this.cameraProfileService
          .saveEventComments(commentJson)
          .pipe(
            tap((data: Comments) => {
              const respData = data.comments;
              this.sortRespData(respData);
              this.reviewComments = data.comments;
              this.cameraProfileService.getEventData(false);
              this.messageService.add({ severity: SlbSeverity.Success, summary: EVENTREVIEWCOMMENTPOST });
            }),
            catchError(() => {
              this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTREVIEWCOMMENTPOSTERROR });

              return of<string>('');
            }),
            takeUntil(this.destroyed)
          )
          .subscribe();
      }

      if (this.currentFlag !== this.flag) {
        let flagValue = !this.currentFlag || this.currentFlag === NOT_REVIEWED ? NONE : this.currentFlag;
        this.cameraProfileService
          .setFlagData(this.cameraDetails.eventId, this.userName, this.userEmail, flagValue)
          .pipe(
            tap((resData: EventDataView) => {
              this.cameraProfileService.getEventData(false);
              this.getFlagCount = resData.eventDetails;
              flagValue =
                !this.currentFlag || flagValue === NONE
                  ? NOT_REVIEWED_CAPS
                  : flagValue[0].toUpperCase() + flagValue.slice(1).toLocaleLowerCase();
              this.messageService.add({
                severity: SlbSeverity.Success,
                summary: `${flagValue} flag added for ${this.cameraDetails.eventId} by ${this.userName}`,
              });
              this.flag = this.currentFlag;
            }),
            catchError(() => {
              this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTFLAGERROR });

              return of<string>('');
            }),
            takeUntil(this.destroyed)
          )
          .subscribe();
      }
    }
    this.comment = '';
    this.isPostDisabled = true;
  }

  public getTimeDifference(receivedDate?: string): string {
    if (!receivedDate) {
      return '';
    } else {
      const date = new Date(receivedDate);
      const now = new Date();
      const timeDifference = now.getTime() - date.getTime();
      const minutes = Math.floor(timeDifference / (1000 * 60));
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const isSameDay = now.toDateString() === date.toDateString();
      if (timeDifference < 1000 * 60) {
        return 'Just now';
      }
      if (isSameDay) {
        if (minutes < 60) {
          if (minutes === 1) {
            return `1 minute ago`;
          } else {
            return `${minutes} minutes ago`;
          }
        } else {
          if (hours === 1) {
            return `1 hour ago`;
          } else {
            return `${hours} hours ago`;
          }
        }
      } else {
        if (days <= 1) {
          return `1 day ago`;
        } else {
          return `${days} days ago`;
        }
      }
    }
  }

  public getLabelHeader(eventDetails: EventDetails | null | undefined): string {
    if (eventDetails?.workflowType) {
      return this.dashboardService.setWorkflowLabel(eventDetails?.workflowType as string);
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.isImgFullScreen = false;
    this.fullScreenImgSrc = '';
    this.destroyed.next(true);
  }

  private validateMediaUrl(): void {
    const mediaData = this.media;
    mediaData.forEach(el => {
      if (el.fileUrl) {
        const requestUrl = new URL(el.fileUrl);
        requestUrl.searchParams.append('token', this.sessionToken);
        fetch(requestUrl.toString())
          .then(res => {
            if (res.status !== 200) {
              /** When token is expired, redirect to login page*/
              if (res.status === 403) {
                this.loginService.login();
              }
              this.removeInvalidMedia(el.type);
            }
          })
          .catch(() => {
            this.removeInvalidMedia(el.type);
          });
      }
    });
  }

  private removeInvalidMedia(type: string): void {
    if (type === 'video') {
      this.media = this.media.filter(m => m.type !== type);
    } else {
      const index = this.media.findIndex(m => m.type === type);
      if (index !== -1) {
        this.media[index].fileUrl = '';
      }
    }
    if (this.media.length > 1) {
      this.noImagePreview = this.media.filter(m => m?.fileUrl && m.fileUrl !== '').length > 0 ? false : true;
    }
  }

  private constructCommentDetails(): void {
    this.commentDetails = [];
    this.rigData = this.check_BU(this.cameraDetails);
    if (this.rigData) {
      this.rigData.forEach(data => {
        if (this.rigValue && this.rigDetails) {
          const currValue = this.rigValue[data.currentValue as keyof RigValue] as number;
          let lowerBound = this.rigDetails[data.lowerBound as keyof RigDetails] as number;
          const upperBound = this.rigDetails[data.upperBound as keyof RigDetails] as number;
          if (typeof lowerBound === 'undefined') {
            lowerBound = 0;
            if (
              data.label === RPM ||
              ((data.label === BVEL || data.label === HOOKLOAD) && this.cameraDetails.buLogic === 3 && typeof upperBound !== 'undefined')
            ) {
              lowerBound = upperBound * -1;
            }
          }
          if (typeof currValue !== 'undefined') {
            const getSelectedUnit = this.userSettingService.getUnit(data.defaultUnit, this.unitSelected.unitData);
            const finalObj: CommentData = {
              min: this.userSettingService.siToChosenUnit(lowerBound, data.defaultUnit, getSelectedUnit),
              max: this.userSettingService.siToChosenUnit(upperBound, data.defaultUnit, getSelectedUnit),
              currentValue: this.userSettingService.siToChosenUnit(currValue, data.defaultUnit, getSelectedUnit),
              unit: getSelectedUnit,
              label: data.label,
              color: this.checkColor(lowerBound, upperBound, currValue),
              range: data.range,
            };
            this.commentDetails.push(finalObj);
          }
        }
      });
    }
  }

  private check_BU(type: EventDetails): CommentDetail[] {
    if (type.buLogic === 3) {
      return RIGDETAILS.RIGDATA_BU3;
    } else {
      return RIGDETAILS.RIGDATA_BU2;
    }
  }

  private checkColor(min: number, max: number, value: number): string {
    let color = GREEN;

    if (value < min || value > max) {
      color = RED;
    }

    return color;
  }
}
