<div class="custom-calendar-title">
  <span mat-dialog-title class="custom-calendar-title">Select a date range</span>
  <slb-modal-close-button
    buttonType="Icon"
    [dialogRef]="dialogRef"
    class="close-icon"
    (click)="cancel()"
    id="custom-calendar-dialog_close_icon"></slb-modal-close-button>
</div>
<div class="error-holder">
  <mat-error *ngIf="isRequired">Please fill the required field</mat-error>
  <mat-error *ngIf="endDateError">End date should be greater than the start date</mat-error>
  <mat-error *ngIf="currentDateError">Start date and end date should be less than the current date</mat-error>
</div>
<div class="calendar-holder d-flex" [formGroup]="formGroup">
  <div class="start-date-holder">
    <mat-form-field slbFormField>
      <mat-label>From</mat-label>
      <input
        formControlName="startDate"
        (formattedValueChange)="formattedCalendar = $event"
        id="custom_calendar_dialog_startDate"
        (valueChange)="calendarEvent = $event"
        [slbDateTimePicker]="dateTimePicker"
        [max]="todayDate"
        matInput />
      <slb-date-time-picker-toggle [for]="dateTimePicker" matSuffix> </slb-date-time-picker-toggle>
      <slb-date-time-picker-popup #dateTimePicker></slb-date-time-picker-popup>
    </mat-form-field>
  </div>
  <div class="end-date-holder">
    <mat-form-field slbFormField>
      <mat-label>To</mat-label>
      <input
        formControlName="endDate"
        (formattedValueChange)="formattedCalendar = $event"
        id="custom_calendar_dialog_endDate"
        (valueChange)="calendarEvent = $event"
        [slbDateTimePicker]="dateTimePicker1"
        [max]="maxValue"
        matInput />
      <slb-date-time-picker-toggle [for]="dateTimePicker1" matSuffix> </slb-date-time-picker-toggle>
      <slb-date-time-picker-popup #dateTimePicker1></slb-date-time-picker-popup>
    </mat-form-field>
  </div>
</div>
<footer class="modal-footer">
  <button slb-secondary-button class="cancel-btn" id="custom_calendar_dialog_cancel_btn" (click)="cancel()">Cancel</button>
  <button slb-button id="custom_calendar_dialog_apply_btn" (click)="apply()">Apply</button>
</footer>
