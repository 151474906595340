import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { EventDetails, GlobalData, Media } from '../../shared/models/eventDetails';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { BehaviorSubject, Subject, catchError, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { EVENTERROR, EVENT_FILTER, FILE_DOWNLOAD_ERROR } from '../../shared/constants/camera-profile-constant';
import { PageEvent } from '@angular/material/paginator';
import { PopUpService } from '../../shared/services/pop-up.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { DashboardConstants } from '../../shared/constants/dashboard-constants';
import { GlobalViewService } from 'src/app/shared/services/global-view.service';
import { DatePipe } from '@angular/common';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import { TimeZone } from '../../shared/models/dashabordAlertData';

@Component({
  selector: 'app-global-event',
  templateUrl: './global-event.component.html',
  styleUrls: ['./global-event.component.scss'],
})
export class GlobalEventComponent implements OnInit, OnDestroy {
  public violationData: EventDetails[];
  public viewMode = 'grid';
  public selectedFiles: EventDetails[];
  public checkAll: boolean;
  public violationDataList: EventDetails[];
  public isLoading: boolean;
  public currentZone = '';
  public refreshedTimezone = '';
  public totalRecords = 0;
  public reviewFilterList = EVENT_FILTER;
  public paginatedData: PageEvent = JSON.parse(JSON.stringify(DashboardConstants.DEFAULT_PAGINATION));
  public isDownloadStarted = false;
  public noEventData: boolean;
  public eventId: number;
  public startDate: Date;
  public endDate: Date;
  public isDisableButton: boolean;
  public filterData: { [key: string]: string[] };

  private destroyed = new Subject();
  private pageNumber: number;
  private recordsPerPage: number;
  private eventState = new BehaviorSubject(this.paginatedData);
  private eventState$ = this.eventState.asObservable();
  private showAllEvents: string;
  private isPaginated = false;

  constructor(
    private cameraProfileService: CameraProfileService,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private popupService: PopUpService,
    private globalViewService: GlobalViewService,
    private timeZoneService: TimeZoneService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedFiles = [];
    this.noEventData = false;
    this.isDisableButton = false;
    this.pageNumber = DashboardConstants.PAGE_NUMBER;
    this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((timeZoneDetails: TimeZone) => {
          this.currentZone = timeZoneDetails.value;
        })
      )
      .subscribe();
  }

  public switchView(event: { value: string }): void {
    this.viewMode = event.value;
  }

  public downloadSelectedFile(): void {
    const downloadUrls: number[] = [];
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(file => {
        const medias = file.media.filter((media: Media) => media.fileUrl);
        if (medias.length) {
          downloadUrls.push(file.eventId);
        }
      });
      if (downloadUrls && downloadUrls.length > 0) {
        this.downloadFiles(downloadUrls);
      } else {
        this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
      }
    }
  }

  public async openReviewPopup(): Promise<void> {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      await this.popupService.openEditReviewConfiguration(this.selectedFiles);
    }
  }

  public getSelectedFiles(value: EventDetails[]): void {
    this.selectedFiles = value;
    if (this.violationDataList.length > this.selectedFiles.length) {
      this.checkAll = false;
    } else if (
      this.violationDataList.length === this.selectedFiles.length &&
      this.violationDataList.length !== 0 &&
      this.selectedFiles.length !== 0
    ) {
      this.checkAll = true;
    }
    this.updateSelection();
  }

  public checkAllFiles(): void {
    if (this.checkAll) {
      this.selectedFiles = this.violationDataList;
      this.violationDataList.map((data: EventDetails) => (data['isChecked'] = true));
      this.violationDataList = [...this.violationDataList];
    } else {
      this.violationDataList.map((data: EventDetails) => (data['isChecked'] = false));
      this.violationDataList = [...this.violationDataList];
      this.selectedFiles = [];
    }
    this.updateSelection();
  }

  public getPaginatedData(event: PageEvent): void {
    if (this.pageNumber !== event.pageIndex + 1 || this.recordsPerPage !== event.pageSize) {
      this.paginatedData = event;
      this.pageNumber = event.pageIndex + 1;
      this.recordsPerPage = event.pageSize;
      this.selectedFiles = [];
      const isFilter = Object.keys(this.filterData).filter(data => this.filterData[data]?.length);
      if (isFilter?.length) {
        this.eventState.next(this.paginatedData);
        this.isDisableButton = false;
      } else {
        this.isDisableButton = true;
      }
    }
  }

  public setEventId(): void {
    this.eventId = 0;
  }

  public switchDays(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.pageNumber = DashboardConstants.PAGE_NUMBER;
    this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
    this.paginatedData = JSON.parse(JSON.stringify(DashboardConstants.DEFAULT_PAGINATION));
    if (this.filterData) {
      const isFilter = Object.keys(this.filterData)?.filter(data => this.filterData[data]?.length);
      if (!isFilter?.length) {
        this.violationDataList = [];
        this.totalRecords = 0;
        this.isDisableButton = true;
      }
    }
  }

  public workFlowEventStatusChange(filterData: { [key: string]: string[] }): void {
    this.filterData = filterData;
    const isFilter = Object.keys(this.filterData).filter(data => this.filterData[data]?.length);
    if (!isFilter?.length) {
      this.violationDataList = [];
      this.totalRecords = 0;
      this.isDisableButton = true;
    }
  }

  public setFirstInitialization(startDate: Date, endDate: Date, filterData: { [key: string]: string[] }): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.filterData = filterData;
    const isFilter = Object.keys(this.filterData).filter(data => this.filterData[data]?.length);
    if (isFilter?.length) {
      this.getEvent();
    }
  }

  ngOnDestroy(): void {
    this.cameraProfileService.setEventTime('');
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private getEvent(): void {
    let startDate: string;
    let endDate: string;
    this.isLoading = true;
    this.eventState$
      .pipe(
        tap(() => {
          this.isPaginated = true;
        }),
        switchMap(() =>
          this.cameraProfileService.eventData$.pipe(
            tap(_value => {
              this.isLoading = true;
              this.totalRecords = 0;
              this.showAllEvents = sessionStorage.getItem('showAllEvents') || '';
              startDate = this.calculateTimeBasedOnTimezone(this.startDate);
              endDate = this.calculateTimeBasedOnTimezone(this.endDate);
              this.selectedFiles = [];
              this.currentZone = sessionStorage.getItem('timezone') ?? '';
              this.setPagination(_value);
            }),
            switchMap(() =>
              this.globalViewService
                .getEventDetails(
                  startDate,
                  endDate,
                  this.filterData,
                  this.showAllEvents,
                  this.pageNumber,
                  this.recordsPerPage,
                  this.currentZone
                )
                .pipe(
                  tap((eventData: GlobalData) => {
                    if (eventData?.data) {
                      this.setEventData(eventData);
                    }
                    if (eventData?.totalRecords === 0) {
                      this.violationDataList = [];
                    }
                    this.isLoading = false;
                  }),
                  catchError(() => {
                    this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTERROR });
                    this.isLoading = false;

                    return of<string>('');
                  }),
                  takeUntil(this.destroyed)
                )
            )
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private setEventData(eventData: GlobalData): void {
    this.violationDataList = eventData?.data;
    this.totalRecords = eventData?.totalRecords;
    this.checkAll = false;
    this.isDisableButton = false;
    this.violationDataList?.forEach(data => {
      data.icon = this.dashboardService.getIconImg(data);
      data.label = this.dashboardService.getReason(data);
      data.rigState = this.dashboardService.getRigEventDetails(data);

      if (data?.isChecked) {
        data.isChecked = false;
      }

      return data;
    });
  }

  private setPagination(value: boolean): void {
    if (!this.isPaginated && value) {
      this.paginatedData.pageIndex = 0;
      this.paginatedData.pageSize = DashboardConstants.RECORDS_PER_PAGE;
      this.paginatedData.length = DashboardConstants.PAGE_NUMBER;
      this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
      this.pageNumber = DashboardConstants.PAGE_NUMBER;
    } else {
      this.isPaginated = false;
    }
  }

  private calculateTimeBasedOnTimezone(date: Date): string {
    if (date) {
      const dateBdOnChosedTimezone = new DatePipe('en-Us').transform(date, 'EEEE, MMMM d, y, h:mm:ss a zzzz', this.currentZone);
      if (dateBdOnChosedTimezone) {
        const basedonCurrentTimezone: Date = new Date(dateBdOnChosedTimezone);

        return basedonCurrentTimezone.toISOString();
      }

      return '';
    }

    return '';
  }

  private downloadFiles(eventId: number[]): void {
    this.cameraProfileService
      .downloadMediaAsZip('', '', eventId)
      .pipe(
        tap((data: string) => {
          if (data && data !== '') {
            this.downloadZipFile(data);
          } else {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
          }
        }),
        catchError(err => {
          console.error(err);
          throw err;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private updateSelection(): void {
    this.violationDataList.forEach((data: EventDetails) => {
      data.isChecked = false;
    });
    this.selectedFiles.forEach((file: EventDetails) => {
      this.violationDataList.forEach((data: EventDetails) => {
        if (file.eventId === data.eventId) {
          data.isChecked = true;
        }
      });
    });
  }

  private downloadZipFile(url: string): void {
    const blob = new Blob([url], {
      type: 'application/zip',
    });
    const bloburl = window.URL.createObjectURL(blob);
    const filename = 'SLB Edge-Vision Intelligence-global';
    const dwldLink = document.createElement('a');

    dwldLink.setAttribute('href', bloburl);
    dwldLink.setAttribute('download', filename + '.zip');
    dwldLink.setAttribute('target', '_blank');
    dwldLink.click();
    dwldLink.remove();
  }
}
