import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CheckBoxList, NotificationDetails, NotificationFilter } from '../models/notification';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EquipmentWorkflow, Notification } from '../models/notification';
import { ApiConstants } from '../constants/api-constant';
import { EmailNotificationHistory, EmailNotificationInit, EmailNotificationSave } from '../models/admin';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notificationData$: Observable<boolean>;
  public notificationData = new BehaviorSubject<boolean>(true);
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
    this.notificationData$ = this.notificationData.asObservable();
  }

  public getSystemData(): Observable<CheckBoxList[]> {
    return this.http.get<CheckBoxList[]>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.NOTIFICATION}system-type-master`);
  }

  public getNotificationType(): Observable<CheckBoxList[]> {
    return this.http.get<CheckBoxList[]>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.NOTIFICATION}notification-type-master`);
  }

  public getEquipment(): Observable<EquipmentWorkflow[]> {
    return this.http.get<EquipmentWorkflow[]>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.NOTIFICATION}equipment-workflow`);
  }

  public getUserNotification(): Observable<Notification> {
    return this.http.get<Notification>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.NOTIFICATION}user-notification`);
  }

  public saveUserNotification(payload: Notification): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.NOTIFICATION}user-notification`, payload);
  }

  public getNotificationData(_payload: NotificationFilter): Observable<NotificationDetails[]> {
    return this.http.post<NotificationDetails[]>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.NOTIFICATION}user-notification-details`,
      _payload
    );
  }

  public getUserNotificationCount(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.NOTIFICATION}user-notification-count`);
  }

  public getOrganization(): Observable<EmailNotificationInit[]> {
    return this.http.get<EmailNotificationInit[]>(`${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EMAILNOTIFICATION}organization`);
  }

  public getEmailNotification(): Observable<EmailNotificationHistory[]> {
    return this.http.get<EmailNotificationHistory[]>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EMAILNOTIFICATION}organization-detail`
    );
  }

  public saveEmailNotification(payload: EmailNotificationSave[]): Observable<EmailNotificationSave[]> {
    return this.http.post<EmailNotificationSave[]>(
      `${this.baseUrl}${ApiConstants.BASEURL}${ApiConstants.EMAILNOTIFICATION}organization-detail`,
      payload
    );
  }
}
